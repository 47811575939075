import React from "react";
import { SessionManager } from "react-session.manager.sk";
import { useLocation, useNavigate } from "react-router-dom";
import { Alert, AlertTitle } from "@mui/material";

export default function GetHighestPage() {
  const { userInfo } = React.useContext(SessionManager);

  const urlDets = new URLSearchParams(useLocation().search); // eslint-disable-line
  const navigate = useNavigate();

  React.useEffect(() => {
    class pageWeight {
      constructor(category, name, weight, urlFormat, addExtra = false) {
        this.category = category;
        this.name = name;
        this.weight = weight;
        this.urlFormat = urlFormat;
        this.addExtra = addExtra;
      }
    }

    const pages = [
      new pageWeight(
        "quotepdf",
        "quotepdf",
        1,
        "/quoteManager/edit?qno=",
        true
      ),
      new pageWeight(
        "quotepdf",
        "quotepdf",
        2,
        "/quoteViewer?dwn=true&qno=",
        true
      ),
    ];

    let found = pages.filter((page) => page.category === urlDets.get("c"));
    if (found.length > 0) {
      let highest = found.reduce((prev, current) =>
        prev.weight > current.weight ? prev : current
      );
      let url = highest.urlFormat;
      if (highest.addExtra) {
        url += urlDets.get("i");
      }

      navigate(url);
    }
  }, [urlDets, userInfo, navigate]);
  return (
    <Alert severity="info">
      <AlertTitle>Redirecting...</AlertTitle>
      Please wait while we redirect you to the highest page you have access to.
    </Alert>
  );
}
