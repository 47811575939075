// We use this for our list of pages that users can access
// This is used to pick what links are show in the drawer for base users
// As well as what routes are created
import React from "react";
import { Routes, Route } from "react-router-dom";
import { SessionManager } from "react-session.manager.sk";

//Icons
import DashboardIcon from "@mui/icons-material/Dashboard";
import SettingsIcon from "@mui/icons-material/Settings";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import KeyIcon from "@mui/icons-material/Key";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import AccountBoxIcon from "@mui/icons-material/AccountBox";

import AssessmentIcon from "@mui/icons-material/Assessment";

//Pages this is the component that will be rendered
import Settings from "./Pages/Main/Settings";
import Notifications from "./Pages/Main/Notifications";
import Profile from "./Pages/Main/Profile";
import Tokens from "./Pages/Main/Tokens";

import AdminDashboard from "./Pages/Admin/Dashboard";
import UserManager from "./Pages/Admin/UserManager";
import RoleManager from "./Pages/Admin/RoleManager";
import AccessControl from "./Pages/Admin/AccessControl";
import UserManagerForm from "./Components/Forms/UserManager";

// Technology Today

import Dashboard from "./MYISP/Pages/DashBoard";

// Quotes
import QuoteManager from "./MYISP/Pages/QuoteManager";
import EditQuote from "./MYISP/Components/Forms/EditQuote";
import AdminQuoteManager from "./MYISP/Pages/AdminQuoteManager";
import QuotieViewer from "./MYISP/Pages/QuoteViewer";

// Clients
import ClientManager from "./MYISP/Pages/ClientManager";
import ClientManagerForm from "./MYISP/Components/Forms/ClientManager";
import ClientJourney from "./MYISP/Components/Forms/ClientJourney";
import AdminClientManager from "./MYISP/Pages/AdminClientManger";
import AdminClientManagerForm from "./MYISP/Components/Forms/AdminClientManager";
import Companies from "./MYISP/Pages/Companies";

// Reports
import Reports from "./MYISP/Pages/Reports";
import { Business, ViewAgenda } from "@mui/icons-material";
import CompanyManager from "./MYISP/Components/Forms/CompanyManager";
import GetHighestPage from "./MYISP/Components/common/GetHighestPage";

// Rescources
import EmailSignature from "./MYISP/Pages/EmailSignature";

// Example

/*
 {
    name: "",
    url: "",
    path: "",
    icon: ,
    element: < />,
    link: true,
  },
*/

// These routes can be accessed by anyone as long as they are logged in
export const NormalRoutes = [
  {
    name: "Dashboard",
    url: "/",
    path: "/",
    icon: DashboardIcon,
    element: <Dashboard />,
    link: true,
  },
  {
    name: "Notifications",
    url: "/notifications",
    path: "/notifications",
    icon: null,
    element: <Notifications />,
    link: false,
  },
  {
    name: "Redirect",
    url: "/redirect",
    path: "/redirect",
    icon: null,
    element: <GetHighestPage />,
    link: false,
  },
  {
    name: "Email Signature",
    url: "/genEmailSignature",
    path: "/genEmailSignature",
    icon: "null",
    element: <EmailSignature />,
    link: false,
  },
];

export const FooterRoutes = [
  {
    name: "Settings",
    url: "/settings",
    path: "/settings/*",
    icon: SettingsIcon,
    element: <Settings />,
    link: true,
    children: [
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "devices",
        element: <Tokens />,
      },
    ],
  },
];

// Requires specific roles to access.
// If no roles are specified no one can access them. This is to ensure the safety of the system.
export const RoleRoutes = [
  {
    name: "Quotes",
    url: "/quoteManager",
    path: "/quoteManager",
    icon: RequestQuoteIcon,
    element: <QuoteManager />,
    link: true,
    children: [{ path: "edit", element: <EditQuote /> }],
  },
  {
    name: "Clients",
    url: "/clientManager",
    path: "/clientManager",
    icon: SupervisorAccountIcon,
    element: <ClientManager />,
    link: true,
    children: [
      { path: "manage", element: <ClientManagerForm /> },
      { path: "journey", element: <ClientJourney /> },
    ],
  },

  {
    name: "Admin Quotes",
    url: "/adminQuoteManager",
    path: "/adminQuoteManager",
    icon: AdminPanelSettingsIcon,
    element: <AdminQuoteManager />,
    link: true,
  },
  {
    name: "Quote Viewer",
    urlL: "/quoteViewer",
    path: "/quoteViewer",
    icons: <ViewAgenda />,
    element: <QuotieViewer />,
    link: false,
  },
  {
    name: "Admin Clients",
    url: "/adminClientManager",
    path: "/adminClientManager",
    icon: AccountBoxIcon,
    element: <AdminClientManager />,
    link: true,
    children: [
      { path: "manage", element: <AdminClientManagerForm /> },
      { path: "journey", element: <ClientJourney /> },
    ],
  },
  {
    name: "Companies",
    url: "/companies",
    path: "/companies",
    icon: Business,
    element: <Companies />,
    link: true,
    children: [{ path: "manage", element: <CompanyManager /> }],
  },

  {
    name: "Reports",
    url: "/reports",
    path: "/reports",
    icon: AssessmentIcon,
    element: <Reports />,
    link: true,
  },
];

export const AdminRoutes = [
  {
    name: "Admin Controls",
    url: "/admin",
    path: "/admin",
    icon: AdminPanelSettingsIcon,
    element: <AdminDashboard />,
    link: true,
  },
  {
    name: "User Manager",
    url: "/admin/users",
    path: "/admin/users/*",
    icon: SupervisorAccountIcon,
    element: <UserManager />,
    link: true,
    children: [{ path: "manage", element: <UserManagerForm /> }],
  },
  {
    name: "Role Manager",
    url: "/admin/roles",
    path: "/admin/roles",
    icon: AssignmentIndIcon,
    element: <RoleManager />,
    link: true,
  },
  {
    name: "Access Control",
    url: "/admin/access",
    path: "/admin/access",
    icon: KeyIcon,
    element: <AccessControl />,
    link: true,
  },
];

// You can ignore everything below this

// This function is used to render the routes
export function RenderRoutes() {
  const { isAdmin, userInfo } = React.useContext(SessionManager);

  const filtered = RoleRoutes.filter((val) => {
    return userInfo?.pages?.includes(val.name);
  });

  const BaseRs = NormalRoutes.concat(filtered);
  const BR = BaseRs.concat(FooterRoutes);
  const AR = AdminRoutes;

  const finalRoutes = isAdmin ? BR.concat(AR) : BR;

  return (
    <Routes>
      {finalRoutes.map((route, i) => (
        <Route key={i} path={route.path} element={route.element}>
          {route.children &&
            route.children.map((child, i) => (
              <Route
                key={`child ${i}`}
                path={child.path}
                element={child.element}
              />
            ))}
        </Route>
      ))}
      <Route path="*" element={<Dashboard />} />
    </Routes>
  );
}

export function AvailableRoutes() {
  const { isAdmin, userInfo } = React.useContext(SessionManager);

  const filtered = RoleRoutes.filter((val) => {
    return userInfo?.pages?.includes(val.name);
  });

  const BaseRs = NormalRoutes.concat(filtered);
  const BR = BaseRs.concat(FooterRoutes);
  const AR = AdminRoutes;

  const finalRoutes = isAdmin ? BR.concat(AR) : BR;

  return finalRoutes;
}
