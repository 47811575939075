import React from "react";
import {
  Stack,
  Typography,
  DialogTitle,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { Outlet, Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";

import AddClient from "../Components/Forms/AddClient";
import {
  getClientPages,
  getClients,
  updateJourneyStatus,
  getClientByID,
} from "../API/Sales";

import FloatingFormButton from "../../Components/common/FloatingFormButton";

import UniversalTable from "../../Components/common/UniversalTable";

import {
  LoadingProvider,
  LoadingContext,
} from "../Components/common/LoadingProvider";

import LazyLoadingIndicator from "../Components/common/LazyLoadingIndicator";
import MoreTableButton from "../Components/common/MoreTableButton";

export default function Main() {
  return (
    <LoadingProvider
      getFilterData={() => ({ currentPage: 1 })}
      getData={getClients}
      getPages={getClientPages}
    >
      <ClientsWrapper />
    </LoadingProvider>
  );
}

function ClientsWrapper() {
  const {
    loadingIndicator,
    currentPage,
    totalPages,
    loading,
    loadFiltered,
    data,
  } = React.useContext(LoadingContext);

  // Table components

  const headers = [
    {
      id: "companyName",
      label: "Company",
      searchable: true,
    },
    {
      id: "fullName",
      label: "Full Name",
      searchable: true,
    },
    {
      id: "email",
      label: "Email",
      searchable: true,
    },
    {
      id: "more",
      label: "",
      component: (value) => <MoreOptions value={value} />,
    },
  ];
  // Customer Data
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <Typography variant="h4" color="primary" gutterBottom>
        Client Manager
      </Typography>
      <Stack spacing={2} sx={{ my: 1 }}>
        <Box>
          <FloatingFormButton
            title="Add new client"
            open={open}
            setOpen={setOpen}
          >
            <DialogTitle>Add new client</DialogTitle>
            <AddClient
              onComplete={() => {
                setOpen(false);
                loadFiltered();
              }}
            />
          </FloatingFormButton>
        </Box>
      </Stack>
      <LazyLoadingIndicator
        show={loadingIndicator}
        currentPage={currentPage}
        totalPages={totalPages}
      />

      <UniversalTable
        headers={headers}
        loading={loading}
        setLoading={loadFiltered}
        data={data}
        name={"Clients"}
      />

      <Outlet
        context={{
          loadFiltered,
          origin: "/clientManager",
          updateJourney: updateJourneyStatus,
          getClient: getClientByID,
        }}
      />
    </>
  );
}
const MoreOptions = ({ value }) => (
  <MoreTableButton id={value.quote_no}>
    <List dense>
      <ListItem disablePadding>
        <ListItemButton component={Link} to={`Manage?i=${value.id}`}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText primary="Edit" />
        </ListItemButton>
      </ListItem>
    </List>
    <ListItem disablePadding>
      <ListItemButton component={Link} to={`journey?i=${value.id}`}>
        <ListItemIcon>
          <DirectionsWalkIcon />
        </ListItemIcon>
        <ListItemText primary="Journey" />
      </ListItemButton>
    </ListItem>
  </MoreTableButton>
);
