import React from "react";
import { toast } from "react-toastify";
import {
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ButtonGroup,
  Button,
} from "@mui/material";

import { ListAltOutlined, PictureAsPdf, ViewAgenda } from "@mui/icons-material";

import FilterArea from "../../Components/common/FilterArea";
import {
  getAllQuotes,
  getQuotePDF,
  getAllCompaniesQuotes,
  getQuotePages,
} from "../API/SalesAdmin";
import UniversalTable from "../../Components/common/UniversalTable";
import { Link, Outlet } from "react-router-dom";

import { dateRange, convertToBool } from "../Components/common/FilterUtility";
import LazyLoadingIndicator from "../Components/common/LazyLoadingIndicator";

import {
  LoadingProvider,
  LoadingContext,
} from "../Components/common/LoadingProvider";
import MoreTableButton from "../Components/common/MoreTableButton";

import NumberFormat from "../../Components/common/NumberFormater";

export default function Main() {
  // advanced search
  const [currency, setCurrency] = React.useState(""),
    [companyName, setCompanyName] = React.useState(""),
    [part, setPart] = React.useState(""),
    [exempt, setExempt] = React.useState(""),
    [date, setDate] = React.useState("");

  const getFilterData = (filter) => {
    const from = new Date(date[1]);
    const to = new Date(date[2]);

    const data = filter
      ? {
          currency,
          companyName,
          part,
          exempt: convertToBool(exempt, "Yes", [true, false]),
          from:
            date[0] === "less" || date[0] === ""
              ? null
              : from.toLocaleString("en-GB", { timeZone: "UTC" }),
          to:
            date[0] === "greater" || date[0] === ""
              ? null
              : to.toLocaleString("en-GB", { timeZone: "UTC" }),
          currentPage: 1,
        }
      : {
          currency: null,
          companyName: null,
          part: null,
          exempt: null,
          from: null,
          to: null,
          currentPage: 1,
        };
    return data;
  };

  return (
    <>
      <LoadingProvider
        getFilterData={getFilterData}
        getData={getAllQuotes}
        getPages={getQuotePages}
      >
        <LoadingWrapper
          setCurrency={setCurrency}
          currency={currency}
          setCompanyName={setCompanyName}
          companyName={companyName}
          setPart={setPart}
          part={part}
          setExempt={setExempt}
          exempt={exempt}
          setDate={setDate}
          date={date}
        />
      </LoadingProvider>
    </>
  );
}

function LoadingWrapper({
  setCurrency,
  currency,
  setCompanyName,
  companyName,
  setPart,
  part,
  setExempt,
  exempt,
  setDate,
  date,
}) {
  //context data
  const {
    loadingIndicator,
    currentPage,
    totalPages,
    loading,
    data,
    gotAll,
    allData,
    setData,
    loadFiltered,
  } = React.useContext(LoadingContext);

  const [table, setTable] = React.useState("quote");

  // Table components
  const [genQuote, setGenQuote] = React.useState(false);

  const MoreButton = ({ value }) => (
    <MoreTableButton id={value.quote_no}>
      <List dense>
        <ListItem disablePadding>
          <ListItemButton onClick={() => getPDF(value)}>
            <ListItemIcon>
              <PictureAsPdf />
            </ListItemIcon>
            <ListItemText>Downlaod PDF</ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            component={Link}
            to={`/quoteViewer?qno=${value.quote_no}`}
          >
            <ListItemIcon>
              <ViewAgenda />
            </ListItemIcon>
            <ListItemText>View</ListItemText>
          </ListItemButton>
        </ListItem>
      </List>
    </MoreTableButton>
  );

  const getPDF = (data) => {
    setGenQuote(true);
    getQuotePDF(data.quote_no, data.company_name)
      .then((res) => {
        toast.success(res.message);
        setGenQuote(false);
      })
      .catch((err) => {
        toast.error("Error generating PDF");
      })
      .finally(() => {
        setGenQuote(false);
      });
  };

  const items = [
    {
      id: "partNumber",
      searchable: true,
      label: "Part Number",
    },
    {
      id: "description",
      searchable: true,
      label: "Description",
    },
    {
      id: "quantity",
      searchable: true,
      label: "Quantity",
    },
    {
      id: "price",
      searchable: true,
      label: "Unit Price EXC",
      component: (value) => NumberFormat(value.price),
    },
  ];

  const headers = [
    {
      id: "items",
      subRow: true,
      label: "",
      iconColor: "info",
      headers: items,
      subTitle: "Items",
      openIcon: <ListAltOutlined />,
    },
    {
      id: "sales_person",
      label: "Account Manager",
      searchable: true,
    },
    {
      id: "quote_no",
      label: "Quote Number",
      searchable: true,
    },
    {
      id: "company_name",
      searchable: true,
      label: "Company",
    },
    {
      id: "date_created",
      searchable: true,
      label: "Date Created",
      date: true,
    },
    {
      id: "date_quoted",
      searchable: true,
      label: "Date Quoted",
      date: true,
    },
    {
      id: "total",
      searchable: true,
      label: "Total",
    },
    {
      id: "pdf",
      label: "",
      component: (value) => <MoreButton value={value} />,
    },
  ];

  // advanced search

  const [clear, setClear] = React.useState(false);

  // advanced search functions

  const handleAdvancedSearch = () => {
    const findItem = (item) => {
      return (
        item.partNumber.toLowerCase().includes(part.toLowerCase()) ||
        item.description.toLowerCase().includes(part.toLowerCase())
      );
    };
    if (gotAll) {
      const filtered = allData.filter(
        (data) =>
          [data.company_name.toLowerCase(), ""].includes(
            companyName.toLowerCase()
          ) &&
          dateRange(date[1], date[2], data.date_created) &&
          [data.exempt, null].includes(convertToBool(exempt, "Yes")) &&
          data.currency.includes(currency) &&
          data.items.find(findItem)
      );
      setData(filtered);
      return;
    }
    loadFiltered();
  };
  const clearAdvancedSearch = () => {
    setCompanyName("");
    setCurrency("");
    setPart("");
    setExempt("");
    setDate(["", null, null]);
    setClear(true);
  };

  return (
    <>
      <Typography variant="h4" color="primary" gutterBottom>
        Admin Quote Manager
      </Typography>

      <FilterArea
        categories={[
          {
            label: "Company",
            type: "combo",
            value: companyName,
            setValue: setCompanyName,
            getData: getAllCompaniesQuotes,
            clear,
            setClear,
          },
          {
            label: "Currency",
            type: "select",
            options: ["KES", "USD"],
            value: currency,
            setValue: setCurrency,
          },
          {
            label: "Part / Desciption",
            type: "search",
            options: [],
            value: part,
            setValue: setPart,
          },
          {
            label: "Vat Exempt",
            type: "select",
            options: ["Yes", "No"],
            value: exempt,
            setValue: setExempt,
          },
          {
            label: "Date Created",
            type: "date",
            options: [],
            value: date,
            setValue: setDate,
          },
        ]}
        startFilter={handleAdvancedSearch}
        clearFilter={clearAdvancedSearch}
        isLoading={loading}
      />

      <ButtonGroup sx={{ mt: 2 }}>
        <Button
          variant={table === "quote" ? "contained" : "outlined"}
          onClick={() => {
            setTable("quote");
          }}
        >
          Quotes
        </Button>
        <Button
          variant={table === "proposal" ? "contained" : "outlined"}
          onClick={() => {
            setTable("proposal");
          }}
        >
          Proposals
        </Button>
      </ButtonGroup>
      <LazyLoadingIndicator
        show={loadingIndicator}
        currentPage={currentPage}
        totalPages={totalPages}
      />
      {table === "quote" && (
        <UniversalTable
          headers={headers}
          loading={loading}
          setLoading={loadFiltered}
          data={data.filter((value) => !value.proposal)}
          name={"Quotes"}
        />
      )}
      {table === "proposal" && (
        <UniversalTable
          headers={headers}
          loading={loading}
          setLoading={loadFiltered}
          data={data.filter((value) => value.proposal)}
          name={"Proposal"}
        />
      )}

      <Dialog open={genQuote}>
        <DialogTitle>Generating PDF</DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </DialogContent>
      </Dialog>

      <Outlet />
    </>
  );
}
