import React from "react";
import {
  Stack,
  Typography,
  DialogTitle,
  Divider,
  Tab,
  Paper,
  Card,
  CardContent,
  CardActions,
  Skeleton,
  Chip,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import UniversalTable from "../../Components/common/UniversalTable";

import AddClient from "../Components/Forms/AddClient";
import {
  getAllTransgerRequests,
  getClientPages,
  getClients,
  getTranferRequestCount,
  handleTransfer,
  getAllAccountManagers,
  getAllCompaniesClients,
  getClientReport,
  clearReports,
  getClientByID,
  updateJourneyStatus,
} from "../API/SalesAdmin";

import { Outlet } from "react-router-dom";
import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import { toast } from "react-toastify";
import FloatingFormButton from "../../Components/common/FloatingFormButton";

import { dateRange } from "../Components/common/FilterUtility";
import LazyLoadingIndicator from "../Components/common/LazyLoadingIndicator";
import FilterArea from "../../Components/common/FilterArea";

import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";

import {
  Assessment,
  CancelOutlined,
  CheckCircleOutline,
} from "@mui/icons-material";

import {
  LoadingProvider,
  LoadingContext,
} from "../Components/common/LoadingProvider";
import MoreTableButton from "../Components/common/MoreTableButton";

export default function AdminClientManager() {
  const [tabVal, setTabVal] = React.useState("0");
  const [transferCount, setTransferCount] = React.useState(0),
    [loading, setLoading] = React.useState(true);

  // Advanced Search
  const [accountManager, setAccountManager] = React.useState(""),
    [companyName, setCompanyName] = React.useState(""),
    [newClient, setNewClient] = React.useState("");

  const handleChange = (event, newValue) => {
    setTabVal(newValue);
    setLoading(true);
  };

  React.useEffect(() => {
    if (loading)
      getTranferRequestCount().then((res) => {
        setTransferCount(res.count);
        setLoading(false);
      });
  }, [loading]);

  const getFilterData = (filter) => {
    const data = filter
      ? {
          managerEmail: accountManager,
          newClient: newClient === "New Clients" ? true : false,
          companyName: companyName,
          currentPage: 1,
        }
      : {
          managerEmail: null,
          newClient: false,
          companyName: null,
          currentPage: 1,
        };
    return data;
  };

  const [reload, setReload] = React.useState(false);

  const startReload = () => {
    setReload(true);
  };

  React.useEffect(() => {
    if (reload) setReload(false);
  }, [reload]);

  return (
    <>
      <Typography variant="h4" color="primary" gutterBottom>
        Admin Client Manager
      </Typography>
      <Paper variant="outlined">
        <TabContext value={tabVal}>
          <TabList onChange={handleChange} sx={{ p: 1 }}>
            <Tab label="All Clients" value={"0"} />
            <Tab
              label={
                transferCount > 0
                  ? `Transfer Requests (${transferCount})`
                  : "Transfer Requests"
              }
              value={"1"}
            />
          </TabList>

          <TabPanel value={"0"}>
            <LoadingProvider
              getFilterData={getFilterData}
              getData={getClients}
              getPages={getClientPages}
            >
              <MainPanel
                setAccountManager={setAccountManager}
                accountManager={accountManager}
                setCompanyName={setCompanyName}
                companyName={companyName}
                setNewClient={setNewClient}
                newClient={newClient}
                reload={reload}
              />
            </LoadingProvider>
          </TabPanel>
          <TabPanel value={"1"}>
            <TransferPanel />
          </TabPanel>
        </TabContext>
      </Paper>
      <Outlet
        context={{
          startReload,
          origin: "/adminClientManager",
          getClient: getClientByID,
          updateJourney: updateJourneyStatus,
        }}
      />
    </>
  );
}

function MainPanel({
  setAccountManager,
  accountManager,
  setCompanyName,
  companyName,
  setNewClient,
  newClient,
  reload,
}) {
  //context data
  const {
    loadingIndicator,
    currentPage,
    totalPages,
    loading,
    data,
    gotAll,
    allData,
    setData,
    loadFiltered,
  } = React.useContext(LoadingContext);

  // Table components
  const MoreOptions = ({ value }) => (
    <MoreTableButton id={value.quote_no}>
      <List dense>
        <ListItem disablePadding>
          <ListItemButton component={Link} to={`Manage?i=${value.id}`}>
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            <ListItemText primary="Edit" />
          </ListItemButton>
        </ListItem>
      </List>
      <ListItem disablePadding>
        <ListItemButton
          component={Link}
          to={`journey?i=${value.id}&admin=true`}
        >
          <ListItemIcon>
            <DirectionsWalkIcon />
          </ListItemIcon>
          <ListItemText primary="Journey" />
        </ListItemButton>
      </ListItem>
    </MoreTableButton>
  );

  // Table headers
  const tableHeaders = [
    {
      id: "AccountManager",
      label: "Account Manager",
      searchable: true,
    },
    {
      id: "companyName",
      label: "Company",
      searchable: true,
    },
    {
      id: "email",
      label: "Email",
      searchable: true,
    },
    {
      id: "fullName",
      label: "Full Name",
      searchable: true,
    },
    {
      id: "lastQuoted",
      label: "Last Quoted",
      component: (value) => {
        const lastQuoted = value ? new Date(value.lastQuoted) : "N/A";
        const current = new Date();

        var diff = current.getTime() - lastQuoted.getTime();
        var daydiff = diff / (1000 * 60 * 60 * 24);

        return (
          <Chip
            color={
              daydiff > 180 ? "error" : daydiff > 90 ? "warning" : "success"
            }
            label={`Days ${daydiff.toFixed(0)}`}
          />
        );
      },
    },
    {
      id: "verified",
      label: "Verified",
      component: (value) =>
        value.verified ? (
          <CheckCircleOutline color="success" />
        ) : (
          <CancelOutlined color="error" />
        ),
    },
    {
      id: "more",
      label: "",
      component: (value) => <MoreOptions value={value} />,
    },
  ];

  const [open, setOpen] = React.useState(false);
  const [clear, setClear] = React.useState(false);

  // advanced search functions

  const handleAdvancedSearch = () => {
    const date = new Date(); //current date
    const highRange = Date.now();
    const lowRange =
      newClient === "New Clients"
        ? date.setDate(date.getDate() - 30) // removes 30 days from the current date
        : -Infinity;
    if (gotAll) {
      const filtered = allData.filter(
        (data) =>
          data.companyName.toLowerCase().includes(companyName.toLowerCase()) &&
          data.managerEmail
            .toLowerCase()
            .includes(accountManager.toLowerCase()) &&
          dateRange(lowRange, highRange, data.date)
      );
      setData(filtered);
      return;
    }
    loadFiltered();
  };

  const clearAdvancedSearch = () => {
    setAccountManager("");
    setCompanyName("");
    setNewClient("");
    setClear(true);
  };

  React.useEffect(() => {
    if (reload) loadFiltered();
  }, [reload, loadFiltered]);

  return (
    <>
      <FloatingFormButton title="Add new client" open={open} setOpen={setOpen}>
        <DialogTitle>Add new client</DialogTitle>
        <AddClient
          onComplete={() => {
            setOpen(false);
            loadFiltered();
          }}
        />
      </FloatingFormButton>
      <Box sx={{ my: 2 }}>
        <ReportButton
          loading={loading}
          options={{ accountManager, companyName, newClient }}
        />
      </Box>
      <FilterArea
        categories={[
          {
            label: "Company",
            type: "combo",
            value: companyName,
            setValue: setCompanyName,
            getData: getAllCompaniesClients,
            clear,
            setClear,
          },
          {
            label: "Account Manager",
            type: "combo",
            value: accountManager,
            setValue: setAccountManager,
            optionLabel: "email",
            valueLabel: "email",
            getData: getAllAccountManagers,
            clear,
            setClear,
          },
          {
            label: "Clients",
            type: "radio",
            options: ["All Clients", "New Clients"],
            value: newClient,
            setValue: setNewClient,
          },
        ]}
        startFilter={handleAdvancedSearch}
        clearFilter={clearAdvancedSearch}
        isLoading={loading}
      />
      <LazyLoadingIndicator
        show={loadingIndicator}
        currentPage={currentPage}
        totalPages={totalPages}
      />
      <UniversalTable
        headers={tableHeaders}
        data={data}
        loading={loading}
        setLoading={loadFiltered}
        name={"Clients"}
      />
    </>
  );
}

function ReportButton({ loading, options }) {
  const [all, setAll] = React.useState(true);
  const [generating, setGenerating] = React.useState(false);

  const btnExtraText = all ? "All Clients" : "Based on Filter";
  const btnText = `Generate Report - (${btnExtraText})`;

  React.useEffect(() => {
    if (
      options.accountManager === "" &&
      options.companyName === "" &&
      options.newClient === "All Clients"
    )
      setAll(true);
    else setAll(false);
  }, [options]);

  const handleClick = () => {
    setGenerating(true);
    const rName = all ? "All Clients" : "Filtered";

    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    const formattedToday = dd + "_" + mm + "_" + yyyy;

    getClientReport(
      `Client Report ${formattedToday} - ${rName}`,
      all ? null : options
    )
      .then((res) => {
        if (res.status === "success") {
          toast.success(res.message);
        } else {
          toast.error(
            "There was an error generating the report. Please try again later.",
            { id: "report-error" }
          );
        }
      })
      .catch((err) => {
        toast.error(
          "There was an error generating the report. Please try again later.",
          { id: "report-error" }
        );
      })
      .finally(() => {
        clearReports();
        setGenerating(false);
      });
  };

  return (
    <LoadingButton
      loading={loading || generating}
      variant="outlined"
      color="info"
      startIcon={<Assessment />}
      onClick={handleClick}
    >
      {btnText}
    </LoadingButton>
  );
}

function TransferPanel() {
  const [transferList, setTransferList] = React.useState([]),
    [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    if (loading)
      getAllTransgerRequests().then((res) => {
        setTransferList(res);
        setLoading(false);
      });
  }, [loading]);

  return (
    <>
      {loading ? (
        <Stack>
          <Skeleton height={200} />
          <Skeleton height={200} />
        </Stack>
      ) : transferList.length > 0 ? (
        transferList.map((item, index) => (
          <TransferCard
            key={index}
            id={item.clientID}
            coName={item.companyName}
            coEmail={item.companyEmail}
            user={item.userEmail}
            current={item.current}
            startLoading={() => setLoading(true)}
          />
        ))
      ) : (
        <Typography variant="h5">No transfer requests</Typography>
      )}
    </>
  );
}

function TransferCard({ id, coName, coEmail, user, current, startLoading }) {
  const [loading, setLoading] = React.useState(false);

  const handleAccept = () => {
    setLoading(true);
    const Data = {
      clientID: id,
      userEmail: user,
      type: "accept",
    };
    handleTransfer(Data).then(() => {
      toast.success("Transfer request accepted");
      startLoading();
    });
  };

  const handleReject = () => {
    setLoading(true);
    const Data = {
      clientID: id,
      userEmail: user,
      type: "reject",
    };
    handleTransfer(Data).then(() => {
      toast.success("Transfer request rejected");
      startLoading();
    });
  };

  return (
    <>
      <Card sx={{ m: 1, mb: 2 }}>
        <CardContent>
          <Typography variant="h5" color="primary" sx={{ mb: 1 }}>
            <Chip label="Move" color="secondary" /> {coName} <Chip label="TO" />{" "}
            {user}
          </Typography>
          <Typography variant="h6" color="textSecondary">
            Client Details
          </Typography>
          <Divider sx={{ my: 1 }} />
          <Typography variant="body1" color="textSecondary">
            Email: {coEmail}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            Current Account Manager: {current}
          </Typography>
        </CardContent>
        <CardActions
          sx={{
            bgcolor: "background.default",
            p: 1,
            justifyContent: "flex-end",
          }}
        >
          <LoadingButton
            variant="contained"
            color="success"
            loading={loading}
            onClick={handleAccept}
          >
            Accept
          </LoadingButton>
          <LoadingButton
            variant="outlined"
            color="error"
            loading={loading}
            onClick={handleReject}
          >
            Reject
          </LoadingButton>
        </CardActions>
      </Card>
    </>
  );
}
