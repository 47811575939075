import React from "react";
import { toast } from "react-toastify";
import {
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  ButtonGroup,
  Button,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { ListAltOutlined, PictureAsPdf } from "@mui/icons-material";
import { Link } from "react-router-dom";

import {
  getQuotePages,
  getQuotePDF,
  getAllCompaniesQuotes,
  getAllQuotes,
} from "../API/Sales";

import { Outlet } from "react-router-dom";
import FloatingFormButton from "../../Components/common/FloatingFormButton";
import CreateQuote from "../Components/Forms/CreateQuote";

import { dateRange, convertToBool } from "../Components/common/FilterUtility";

import FilterArea from "../../Components/common/FilterArea";
import LazyLoadingIndicator from "../Components/common/LazyLoadingIndicator";

import UniversalTable from "../../Components/common/UniversalTable";

import {
  LoadingProvider,
  LoadingContext,
} from "../Components/common/LoadingProvider";
import NumberFormat from "../../Components/common/NumberFormater";
import MoreTableButton from "../Components/common/MoreTableButton";

export default function Main() {
  // advanced search
  const [currency, setCurrency] = React.useState(""),
    [companyName, setCompanyName] = React.useState(""),
    [part, setPart] = React.useState(""),
    [exempt, setExempt] = React.useState(""),
    [date, setDate] = React.useState("");

  const getFilterData = (filter) => {
    const from = new Date(date[1]);
    const to = new Date(date[2]);

    const exemptVal = convertToBool(exempt, "Yes", [true, false]);

    const data = filter
      ? {
          currency: currency,
          companyName: companyName,
          part: part,
          exempt: exemptVal === "" ? null : exemptVal,
          from:
            date[0] === "less" || date[0] === ""
              ? null
              : from.toLocaleString("en-GB", { timeZone: "UTC" }),
          to:
            date[0] === "greater" || date[0] === ""
              ? null
              : to.toLocaleString("en-GB", { timeZone: "UTC" }),
          currentPage: 1,
        }
      : {
          currency: null,
          companyName: null,
          part: null,
          exempt: null,
          from: null,
          to: null,
          currentPage: 1,
        };
    return data;
  };

  return (
    <>
      <LoadingProvider
        getFilterData={getFilterData}
        getData={getAllQuotes}
        getPages={getQuotePages}
      >
        <LoadingWrapper
          setCurrency={setCurrency}
          currency={currency}
          setCompanyName={setCompanyName}
          companyName={companyName}
          setPart={setPart}
          part={part}
          setExempt={setExempt}
          exempt={exempt}
          setDate={setDate}
          date={date}
        />
      </LoadingProvider>
    </>
  );
}

function LoadingWrapper({
  setCurrency,
  currency,
  setCompanyName,
  companyName,
  setPart,
  part,
  setExempt,
  exempt,
  setDate,
  date,
}) {
  //context data
  const {
    loadingIndicator,
    currentPage,
    totalPages,
    loading,
    data,
    gotAll,
    allData,
    setData,
    loadFiltered,
  } = React.useContext(LoadingContext);

  const [table, setTable] = React.useState("quote");
  const [duplicateData, setDuplicateData] = React.useState(null);
  const [open, setOpen] = React.useState(
    localStorage.getItem("create quote") === "true" || false
  );

  React.useEffect(() => {
    localStorage.setItem("create quote", JSON.stringify(open));
    if (!open) localStorage.removeItem("quoteData");
  }, [open]);

  React.useEffect(() => {
    if (open === false) {
      setDuplicateData(null);
    }
  }, [open]);

  // Table components
  const getPDF = (data) => {
    setGenQuote(true);
    getQuotePDF(data.quote_no, data.company_name)
      .then((res) => {
        toast.success(res.message);
        setGenQuote(false);
      })
      .catch((err) => {
        toast.error("Error generating PDF");
      })
      .finally(() => {
        setGenQuote(false);
      });
  };

  const MoreOptions = ({ value }) => (
    <MoreTableButton id={value.quote_no}>
      <List dense>
        <ListItem disablePadding>
          <ListItemButton
            component={Link}
            to={`edit?qno=${encodeURIComponent(value.quote_no)}&proposal=${
              table !== "quote"
            }`}
          >
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            <ListItemText primary="Edit" />
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton onClick={() => getPDF(value)}>
            <ListItemIcon>
              <PictureAsPdf />
            </ListItemIcon>
            <ListItemText primary="Download PDF" />
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              setDuplicateData({
                validity: value.validity,
                delivery: value.delivery,
                currency: value.currency,
                vatExempt: value.vatExempt,
                itemList: value.items,
                clientRefrence: value.clientRefrence,
                clientID: value.clientID,
                proposal: value.proposal,
              });
              setOpen(true);
            }}
          >
            <ListItemIcon>
              <ContentCopyIcon />
            </ListItemIcon>
            <ListItemText
              primary={`Clone ${table === "quote" ? "Quote" : "Proposal"}`}
            />
          </ListItemButton>
        </ListItem>
      </List>
    </MoreTableButton>
  );

  // data for the items sub table
  const items = [
    {
      id: "partNumber",
      label: "Part Number",
    },
    {
      id: "description",
      label: "Description",
    },
    {
      id: "quantity",
      label: "Quantity",
    },
    {
      id: "price",
      label: "Unit Price EXC",
      component: (value) => NumberFormat(Number(value.price).toFixed(2)),
    },
  ];

  // Universal table 2.0 test
  const headers = [
    {
      id: "items",
      subRow: true,
      label: "",
      iconColor: "info",
      headers: items,
      subTitle: "Items",
      openIcon: <ListAltOutlined />,
    },
    {
      id: "quote_no",
      label: "Quote Number",
      searchable: true,
    },
    {
      id: "company_name",
      searchable: true,
      label: "Company",
    },
    {
      id: "date_created",
      searchable: true,
      label: "Date Created",
      date: true,
    },
    {
      id: "date_quoted",
      searchable: true,
      label: "Date Quoted",
      date: true,
    },
    {
      id: "total",
      searchable: true,
      label: "Total",
    },
    {
      id: "more",
      label: "",
      component: (value) => <MoreOptions value={value} />,
    },
  ];

  //utility functions

  const [clear, setClear] = React.useState(false);

  const [genQuote, setGenQuote] = React.useState(false);

  // advanced search functions

  const clearAdvancedSearch = () => {
    setCompanyName("");
    setCurrency("");
    setPart("");
    setExempt("");
    setDate(["", null, null]);
    setClear(true);
  };

  const handleSearch = () => {
    const findItem = (item) => {
      return (
        item.partNumber.toLowerCase().includes(part.toLowerCase()) ||
        item.description.toLowerCase().includes(part.toLowerCase())
      );
    };
    if (gotAll) {
      const filtered = allData.filter(
        (data) =>
          [data.company_name.split(" ||")[0].toLowerCase(), ""].includes(
            companyName.toLowerCase()
          ) &&
          dateRange(date[1], date[2], data.date_created) &&
          [data.vatExempt, null].includes(convertToBool(exempt, "Yes")) &&
          data.currency.includes(currency) &&
          data.items.find(findItem)
      );
      setData(filtered);
      return;
    }
    loadFiltered();
  };

  return (
    <>
      <Typography variant="h4" color="primary" gutterBottom>
        {table === "quote" ? "Quotes" : "Proposals"}
      </Typography>

      <FloatingFormButton
        title={`Create ${table === "quote" ? "Quote" : "Proposal"}`}
        open={open}
        setOpen={setOpen}
        DialogFullScreen
      >
        <CreateQuote
          onComplete={() => {
            setOpen(false);
            loadFiltered();
            localStorage.removeItem("quoteData");
          }}
          duplicateData={duplicateData}
          handleClose={() => {
            setOpen(false);
          }}
          isProposal={table === "proposal"}
        />
      </FloatingFormButton>

      <Dialog open={genQuote}>
        <DialogTitle>Generating PDF</DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </DialogContent>
      </Dialog>
      <Box>
        <FilterArea
          categories={[
            {
              label: "Company",
              type: "combo",
              value: companyName,
              setValue: setCompanyName,
              getData: getAllCompaniesQuotes,
              clear,
              setClear,
            },
            {
              label: "Currency",
              type: "select",
              options: ["KES", "USD"],
              value: currency,
              setValue: setCurrency,
            },
            {
              label: "Part / Desciption",
              type: "search",
              options: [],
              value: part,
              setValue: setPart,
            },
            {
              label: "Vat Exempt",
              type: "select",
              options: ["Yes", "No"],
              value: exempt,
              setValue: setExempt,
            },
            {
              label: "Date Created",
              type: "date",
              options: [],
              value: date,
              setValue: setDate,
            },
          ]}
          startFilter={handleSearch}
          clearFilter={clearAdvancedSearch}
          isLoading={loading}
        />
      </Box>

      <ButtonGroup sx={{ mt: 2 }}>
        <Button
          variant={table === "quote" ? "contained" : "outlined"}
          onClick={() => {
            setTable("quote");
          }}
        >
          Quotes
        </Button>
        <Button
          variant={table === "proposal" ? "contained" : "outlined"}
          onClick={() => {
            setTable("proposal");
          }}
        >
          Proposals
        </Button>
      </ButtonGroup>
      <LazyLoadingIndicator
        show={loadingIndicator}
        currentPage={currentPage}
        totalPages={totalPages}
      />

      {table === "quote" && (
        <UniversalTable
          headers={headers}
          loading={loading}
          setLoading={loadFiltered}
          data={data.filter((value) => !value.proposal)}
          name={"Quotes"}
        />
      )}
      {table === "proposal" && (
        <UniversalTable
          headers={headers}
          loading={loading}
          setLoading={loadFiltered}
          data={data.filter((value) => value.proposal)}
          name={"Proposal"}
        />
      )}

      <Outlet context={{ loadFiltered, origin: "/quoteManager" }} />
    </>
  );
}
