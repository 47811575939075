import React from "react";

import {
  Button,
  ButtonGroup,
  Grid,
  Typography,
  FormGroup,
  Switch,
  SvgIcon,
} from "@mui/material";

import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import LightModeIcon from "@mui/icons-material/LightMode";

import { CustomThemeContext } from "./CustomThemeProvider";

export default function ThemeSlider(props) {
  const { currentTheme, setTheme } = React.useContext(CustomThemeContext);
  const [dark, setDark] = React.useState(
    currentTheme === "dark" ? true : false
  );

  const handleChange = (e) => {
    const checked = e;
    setDark(checked);
    if (checked) setTheme("dark");
    else setTheme("light");
  };

  const ThemeIcon = () => {
    if (dark) {
      return <SvgIcon color="primary" component={DarkModeOutlinedIcon} />;
    } else {
      return <SvgIcon color="secondary" component={LightModeIcon} />;
    }
  };

  if (props.buttonVariant) {
    return (
      <>
        <Typography variant="overline" sx={{ display: "block" }}>
          Mode
        </Typography>
        <ButtonGroup>
          <Button
            startIcon={<SvgIcon component={DarkModeOutlinedIcon} />}
            variant={dark ? "contained" : "outlined"}
            onClick={() => {
              handleChange(true);
            }}
          >
            Dark
          </Button>
          <Button
            startIcon={<SvgIcon component={LightModeIcon} />}
            variant={dark ? "outlined" : "contained"}
            onClick={() => {
              handleChange(false);
            }}
          >
            Light
          </Button>
        </ButtonGroup>
      </>
    );
  }

  return (
    <FormGroup row>
      <Grid container direction="row" alignItems="center">
        <Grid item>
          <Switch
            checked={dark}
            onChange={(e) => handleChange(e.target.checked)}
            name="ThemeSlider"
            color="primary"
          />
        </Grid>
        <Grid item>
          <ThemeIcon />
        </Grid>
      </Grid>
    </FormGroup>
  );
}
