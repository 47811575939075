import React from "react";
import EnhancedTable from "../../Components/CustomTables/UserTable";
import { get_users } from "../../CustomAxios/Protected/AdminManagment";
import TableLoader from "../../Components/Loading/Table";
import Button from "@mui/material/Button";
import RefreshIcon from "@mui/icons-material/Refresh";
import ClearIcon from "@mui/icons-material/Clear";
import { Typography, IconButton, TextField, Tooltip } from "@mui/material";
import { useNavigate, Outlet } from "react-router";
import { useLocation } from "react-router-dom";

function ReloadBtn(setLoading) {
  return (
    <Tooltip title={"Reload"}>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setLoading(true)}
      >
        <RefreshIcon />
      </Button>
    </Tooltip>
  );
}

function SearchArea(props) {
  const { current, setFinalVal } = props;
  const [searchVal, setSearchVal] = React.useState(current);

  const setDefault = () => {
    setSearchVal("");
    setFinalVal("");
  };

  React.useEffect(() => {
    const timeoutId = setTimeout(() => setFinalVal(searchVal), 500);
    return () => clearTimeout(timeoutId);
  }, [searchVal, setFinalVal]);

  return (
    <div key="SearchArea">
      <TextField
        label="Search"
        variant="outlined"
        value={searchVal}
        color="secondary"
        onChange={(e) => setSearchVal(e.target.value)}
        InputProps={{
          endAdornment: (
            <Tooltip title={"Reset Search"}>
              <IconButton
                aria-label="Reset"
                color="secondary"
                onClick={() => setDefault()}
              >
                <ClearIcon />
              </IconButton>
            </Tooltip>
          ),
        }}
      />
    </div>
  );
}

export default function Table(props) {
  const head = [
    {
      id: "id",
      numeric: false,
      disablePadding: true,
      label: "Name",
    },
    {
      id: "details",
      numeric: false,
      disablePadding: false,
      label: "Email",
    },
    {
      id: "phone",
      numeric: false,
      disablePadding: false,
      label: "Phone Number",
    },
    {
      id: "date_created",
      numeric: false,
      disablePadding: false,
      label: "Date Created",
    },
    {
      id: "last_modified",
      numeric: false,
      disablePadding: false,
      label: "Last Modified",
    },
    {
      id: "other",
      numeric: false,
      disablePadding: false,
      label: "Status",
    },
    {
      id: "settings",
      numeric: false,
      disablePadding: false,
      label: "",
    },
  ];
  const [rowMaster, setRowMaster] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [searchDets, setSearchDets] = React.useState("");
  const urlDets = new URLSearchParams(useLocation().search);
  const reload = urlDets.get("r");
  let history = useNavigate();

  React.useEffect(() => {
    if (reload) {
      setLoading(true);
      history("/admin/users", { replace: true });
    }
  }, [reload, history]);

  React.useEffect(() => {
    if (loading)
      get_users().then((res) => {
        setRows(res);
        setRowMaster(res);
        setLoading(false);
      });
  }, [loading]);

  React.useEffect(() => {
    if (searchDets) {
      const term = searchDets.toLocaleLowerCase();
      const filtered = rowMaster.filter(
        (val) =>
          val.email.toLocaleLowerCase().includes(term) ||
          val.FullName.toLocaleLowerCase().includes(term) ||
          val.phone.includes(term)
      );
      setRows(filtered);
    } else setRows(rowMaster);
  }, [searchDets, rowMaster]);

  return (
    <>
      <Outlet />
      <Typography variant="h4" color="primary" sx={{ mb: 1 }}>
        User Manager
      </Typography>
      {loading ? (
        <TableLoader />
      ) : (
        <EnhancedTable
          headers={head}
          data={rows}
          name={ReloadBtn(setLoading)}
          selectedFunction={(data) => console.log("I was called")}
        >
          <SearchArea current={searchDets} setFinalVal={setSearchDets} />
        </EnhancedTable>
      )}
    </>
  );
}
