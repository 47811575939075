import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardActions,
  Collapse,
  Divider,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { toast } from "react-toastify";

import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import ClearIcon from "@mui/icons-material/Clear";
import { LoadingButton } from "@mui/lab";
import { uploadExcel } from "../../../CustomAxios/Protected/AccountManagment";

export default function DangerZone() {
  const [open, setOpen] = React.useState(false);
  const [warningRead, setWarningRead] = React.useState(false);

  const enterTime = 500;
  const exitTime = 250;

  React.useEffect(() => {
    if (open) {
      setTimeout(() => {
        setWarningRead(true);
      }, 1000);
    } else {
      setWarningRead(false);
    }
  }, [open]);

  return (
    <Box sx={{ mt: 2 }}>
      <Collapse
        in={!open}
        timeout={{ appear: 0, enter: enterTime, exit: exitTime }}
      >
        <Divider>
          <Button
            variant="contained"
            color="error"
            startIcon={<ReportProblemOutlinedIcon />}
            onClick={() => setOpen(true)}
          >
            Danger Zone
          </Button>
        </Divider>
      </Collapse>
      <Collapse
        in={open}
        timeout={{ appear: 0, enter: enterTime, exit: exitTime }}
      >
        <Paper variant="outlined" sx={{ p: 2 }}>
          <Alert severity="error">
            <AlertTitle>
              The following functions are not reversible. Please be careful.
            </AlertTitle>
          </Alert>
          <Collapse in={warningRead}>
            <Box sx={{ mt: 2, p: 1 }}>
              <ImportDb />
            </Box>
          </Collapse>
          <Stack
            direction="row"
            spacing={2}
            sx={{ mt: 2 }}
            justifyContent="center"
          >
            <Button
              variant="outlined"
              color="error"
              onClick={() => setOpen(false)}
            >
              Close Danger Zone
            </Button>
          </Stack>
        </Paper>
      </Collapse>
    </Box>
  );
}

function ImportDb() {
  const [file, setFile] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = () => {
    setLoading(true);
    uploadExcel(file)
      .then((res) => {
        if (res.status === "success") {
          toast.success(res.message);
        } else {
          toast.error(res.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Paper elevation={1} sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>
        Import Database
      </Typography>
      <Typography variant="body1" gutterBottom>
        An existing databse can be imported from an excel file the format must
        be ".xlsx". This may take a while depending on the size of the file.
      </Typography>
      <Alert severity="info" sx={{ mb: 2 }}>
        If data is loaded from Heroku data clips, change first column to "Sales
        Person" and save as .xlsx
      </Alert>
      <Card
        elevation={0}
        sx={{
          p: 2,
          mx: "auto",
          width: "fit-content",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {file ? (
          <>
            <Stack spacing={2}>
              <Stack direction="row" spacing={2} alignItems="center">
                <Typography variant="body1">
                  {file.name} - {file.size} bytes
                </Typography>
                <IconButton onClick={() => setFile(null)}>
                  <ClearIcon />
                </IconButton>
              </Stack>
            </Stack>
            <CardActions>
              <LoadingButton
                variant="contained"
                color="success"
                loading={loading}
                onClick={handleSubmit}
              >
                Import Data
              </LoadingButton>
            </CardActions>
          </>
        ) : (
          <Button
            variant="contained"
            component="label"
            startIcon={<UploadFileIcon />}
          >
            Upload
            <input
              hidden
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              multiple
              type="file"
              onChange={(e) => setFile(e.target.files[0])}
            />
          </Button>
        )}
      </Card>
    </Paper>
  );
}
