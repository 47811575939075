import React from "react";
import EnhancedTable from "../../Components/CustomTables/TokenTable";
import {
  get_tokens,
  delete_tokens,
} from "../../CustomAxios/Protected/TokenManagment";
import TableLoader from "../../Components/Loading/Table";
import Button from "@mui/material/Button";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
//import SearchIcon from "@mui/icons-material/Search";

function ReloadBtn(setLoading) {
  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setLoading(true)}
      >
        <RefreshIcon />
      </Button>
    </>
  );
}

function SearchArea() {
  return (
    <>
      {/*<Button variant="contained" color="primary">
        Search <SearchIcon />
      </Button>*/}
    </>
  );
}

export default function Tokens(props) {
  const head = [
    {
      id: "id",
      numeric: false,
      disablePadding: true,
      label: "Name",
    },
    {
      id: "type",
      numeric: false,
      disablePadding: true,
      label: "Device Type",
    },
    {
      id: "last_modified",
      numeric: false,
      disablePadding: false,
      label: "Last Login",
    },
  ];
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [remove, setRemove] = React.useState([]);

  const handleClickOpen = (data) => {
    setRemove(data);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteTokensAccepted = () => {
    delete_tokens(remove).then((res) => {
      toast.success("Session(/s) removed!");
      setLoading(true);
      handleClose();
    });
  };

  React.useEffect(() => {
    if (loading)
      get_tokens().then((res) => {
        setRows(res);
        setLoading(false);
      });
  }, [loading]);

  return (
    <>
      {loading ? (
        <TableLoader />
      ) : (
        <Paper sx={{ padding: 1 }}>
          <Typography variant="h4" color="primary" sx={{ mb: 1 }}>
            Devices
          </Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            Manage your devices that are logged in.
          </Typography>
          <EnhancedTable
            headers={head}
            data={rows}
            name={ReloadBtn(setLoading)}
            selectedFunction={(data) => handleClickOpen(data)}
            SearchArea={SearchArea}
          />
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle>Are you sure?</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-title">
                Do you want to delete the selected sessions, it will log out any
                active sessions associated with the it. This cannot be undone.
              </DialogContentText>
              <DialogActions>
                <Button onClick={handleClose} color="secondary" autoFocus>
                  Cancel
                </Button>
                <Button
                  onClick={deleteTokensAccepted}
                  color="primary"
                  variant="contained"
                >
                  Delete
                </Button>
              </DialogActions>
            </DialogContent>
          </Dialog>
        </Paper>
      )}
    </>
  );
}
