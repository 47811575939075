import React from "react";
import {
  Button,
  FormGroup,
  DialogContent,
  DialogContentText,
  TextField,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  IconButton,
  FormControlLabel,
  FormHelperText,
  Switch,
  Link,
  Slide,
  Dialog,
  Alert,
  Collapse,
  Typography,
  Stack,
  Tooltip,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
import LoadingButton from "@mui/lab/LoadingButton";

import {
  Close,
  LoginRounded,
  Password,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import {
  createpassword_token,
  forgotpassword,
  login,
} from "../../CustomAxios/Protected/AccountManagment";
import { SessionManager } from "react-session.manager.sk";
import { Box } from "@mui/system";
import { useNavigate } from "react-router";
import { Link as RLink } from "react-router-dom";
import TokenInput from "../common/TokenInput";
import { PasswordValidator } from "../common/Validator";
import NewDevice from "./NewDevice";
import Logo from "../../logo";

export default function Login(props) {
  const [main, setMain] = React.useState(true);

  const handleChange = () => {
    setMain((pre) => !pre);
  };

  let navigate = useNavigate();

  return (
    <Dialog
      open={true}
      aria-labelledby="Login-Form"
      onClose={(event, reason) => {
        if (reason !== "backdropClick") navigate("/");
      }}
      fullScreen
    >
      <Box
        sx={{
          background: (theme) =>
            `linear-gradient(135deg, ${alpha(
              theme.palette.primary.dark,
              0.5
            )}, ${theme.palette.background.default})`,
          display: "flex",
          height: "100vh",
          width: "100vw",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        <Box
          sx={{
            ml: 20,
            mt: 20,
            maxWidth: "50vw",
          }}
        >
          <Logo sx={{ height: "150px" }} />
          <Typography
            variant="h5"
            sx={{
              mt: 3,
              ml: 3,
              color: "white",
            }}
          >
            Employee Portal
          </Typography>
        </Box>

        <Box
          sx={{
            position: "absolute",
            bgcolor: "background.paper",
            height: "100%",
            right: 0,
            minWidth: "40vw",
          }}
        >
          <Tooltip title="Close" placement="top" arrow>
            <IconButton
              component={RLink}
              to="/"
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                borderTopLeftRadius: 0,
                padding: 2,
              }}
            >
              <Close />
            </IconButton>
          </Tooltip>
          <Box
            sx={{
              position: "relative",
              top: "50%",
              transform: "translateY(-50%)",
            }}
          >
            <Slide
              direction="left"
              in={main}
              timeout={{ appear: 200, enter: 200, exit: 0 }}
              mountOnEnter
              unmountOnExit
            >
              {LoginForm(props, handleChange)}
            </Slide>
            <Slide
              direction="left"
              in={!main}
              timeout={{ appear: 200, enter: 200, exit: 0 }}
              mountOnEnter
              unmountOnExit
            >
              {ForgotPasswordForm(props, handleChange)}
            </Slide>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
}

function ForgotPasswordForm(props, handleChange) {
  const [email, setEmail] = React.useState("");
  const [emailDis, setEmailDis] = React.useState(false);
  const [token, setToken] = React.useState("");
  const [dis, setDis] = React.useState(true);
  const [approved, setApporved] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const [cpassword, setCPassword] = React.useState("");
  const [error, setError] = React.useState(false);
  const [errorText, setEText] = React.useState(""),
    [errorText2, setEText2] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const [hashVal, setHashVal] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  let history = useNavigate();

  const handleFP = (event) => {
    event.preventDefault();
    setLoading(true);
    if (!approved)
      forgotpassword({ email: email }, history).then((stat) => {
        setLoading(false);
        if (stat.stat === "success") {
          setHashVal(stat.key);
          setApporved(true);
          setEmailDis(true);
        }
      });
    else {
      createpassword_token({
        email: email,
        password: password,
        token: token,
        key: hashVal,
      }).then((stat) => {
        setLoading(false);
        if (stat) closeForgot();
      });
    }
  };

  const closeForgot = () => {
    handleChange();
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  React.useEffect(() => {
    if (password || cpassword) {
      const Data = PasswordValidator(password, cpassword);
      setError(!Data.valid);
      setEText2(Data.message);
      setEText(Data.message2);
    }
    if (approved) {
      if (error || !token || !password) setDis(true);
      else setDis(false);
    } else {
      if (email === "") setDis(true);
      else setDis(false);
    }
  }, [password, cpassword, email, error, approved, token]);

  return (
    <form>
      <DialogContent>
        <Stack spacing={2} alignItems="center" sx={{ mb: 5 }}>
          <Box
            sx={{
              bgcolor: "primary.main",
              color: "primary.contrastText",
              borderRadius: "50%",
              height: 60,
              width: 60,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: 2,
            }}
          >
            <Password sx={{ fontSize: 40 }} />
          </Box>
          <Typography variant="h4" align="center">
            Forgot Password
          </Typography>
        </Stack>
        <TextField
          required
          variant="standard"
          margin="normal"
          label="Email"
          type="email"
          value={email}
          onInput={(e) => setEmail(e.target.value)}
          InputLabelProps={{ style: { paddingLeft: "15px" } }}
          fullWidth
          disabled={emailDis}
          sx={{ minWidth: "40vw" }}
        />
        <Collapse in={approved}>
          <Stack spacing={2} alignItems="center" sx={{ mb: 5 }}>
            <DialogContentText>
              A token was sent to your email, if you can't see it please check
              your spam folder.
            </DialogContentText>
            <TokenInput
              onTokenChange={(e) => setToken(e)}
              PaperProps={{ mx: "auto" }}
            />
            <FormControl required fullWidth error={error} margin="normal">
              <InputLabel htmlFor="password">Password</InputLabel>
              <Input
                id="password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {error ? (
                <FormHelperText>
                  {errorText2.map((val, index) => {
                    return (
                      <span key={`passhelp_${index}`}>
                        {val}
                        <br />
                      </span>
                    );
                  })}
                </FormHelperText>
              ) : null}
            </FormControl>
            <FormControl required fullWidth error={error} margin="normal">
              <InputLabel htmlFor="Confirm-Password">
                Confirm Password
              </InputLabel>
              <Input
                id="Confirm-Password"
                type={showPassword ? "text" : "password"}
                value={cpassword}
                onChange={(e) => setCPassword(e.target.value)}
              />
              {error ? <FormHelperText>{errorText}</FormHelperText> : null}
            </FormControl>
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              colour="secondary"
              size="large"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </Stack>
        </Collapse>
      </DialogContent>
      <Stack spacing={2} sx={{ p: 2 }}>
        <LoadingButton
          onClick={handleFP}
          type="submit"
          variant="contained"
          color="primary"
          autoFocus={true}
          disabled={dis}
          loading={loading}
        >
          Next
        </LoadingButton>
        <Button onClick={closeForgot} color="secondary">
          Cancel
        </Button>
      </Stack>
    </form>
  );
}

function LoginForm(props, handleChange) {
  const { setHeader } = React.useContext(SessionManager);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const [remember, setRemember] = React.useState(true);
  const [dis, setDis] = React.useState(true);
  const [loading, setLoading] = React.useState(false);

  const [regNewDevice, setRegNewDevice] = React.useState(false),
    [newDeviceKey, setNewDeviceKey] = React.useState("");

  let history = useNavigate();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSignIn = () => {
    setLoading(true);
    login(
      { email: email, password: password, remember: remember },
      setHeader,
      history
    )
      .then((res) => {
        if (res) {
          setNewDeviceKey(res);
          history(`/login/registerNewDevice?email=${email}&k=${res}`);
          setRegNewDevice(true);
        }
      })
      .finally(() => setLoading(false));
  };

  React.useEffect(() => {
    if (email === "" || password === "") setDis(true);
    else setDis(false);
  }, [email, password]);

  const onDeviceVerified = () => {
    setRegNewDevice(false);
    history("/");
    handleSignIn();
  };

  const [tabCount, setTabCount] = React.useState(0);

  function getTabCount() {
    const tabs = JSON.parse(localStorage.getItem("tabs")) || {};
    setTabCount(Object.keys(tabs).length); // Update tab count
  }

  window.addEventListener("storage", getTabCount);

  return (
    <Box sx={{ width: "100%" }}>
      <form>
        <DialogContent>
          <Stack spacing={2} alignItems="center" sx={{ mb: 5 }}>
            <Box
              sx={{
                bgcolor: "primary.main",
                color: "primary.contrastText",
                borderRadius: "50%",
                height: 60,
                width: 60,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: 2,
              }}
            >
              <LoginRounded sx={{ fontSize: 40, ml: -1 }} />
            </Box>
            <Typography variant="h4" align="center">
              Login
            </Typography>
          </Stack>
          <Collapse in={tabCount > 1}>
            <Alert severity={"warning"} sx={{ mb: 1 }}>
              You have multiple tabs open, Please close the other tabs before
              logging in.
            </Alert>
          </Collapse>
          <DialogContentText>Sign in to an existing account</DialogContentText>
          <FormControl required fullWidth margin="normal" autoFocus>
            <InputLabel htmlFor="email">Email</InputLabel>
            <Input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormControl>
          <FormControl required fullWidth margin="normal">
            <InputLabel htmlFor="password">Password</InputLabel>
            <Input
              id="password"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    colour="secondary"
                    size="large"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Switch
                    checked={remember}
                    onChange={(e) => {
                      setRemember(e.target.checked);
                    }}
                    name="remember"
                  />
                }
                label="Remember Me"
              />
            </FormGroup>
          </Box>
          <Link
            color="secondary"
            onClick={() => handleChange()}
            sx={{
              cursor: "pointer",
            }}
          >
            forgot password?
          </Link>
          <br />
          <Link component={RLink} to="/signup" color="secondary">
            Don't have an account? Sign Up
          </Link>
        </DialogContent>
        <Stack spacing={2} sx={{ p: 2 }}>
          <LoadingButton
            onClick={(e) => {
              e.preventDefault();
              handleSignIn();
            }}
            type="submit"
            variant="contained"
            color="primary"
            autoFocus={true}
            disabled={dis}
            loading={loading}
          >
            Sign In
          </LoadingButton>
        </Stack>
      </form>

      <NewDevice
        open={regNewDevice}
        email={email}
        key={newDeviceKey}
        onComplete={onDeviceVerified}
      />
    </Box>
  );
}
