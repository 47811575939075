import React from "react";
import {
  Button,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  IconButton,
  FormHelperText,
  Dialog,
  DialogTitle,
  CardActionArea,
  Stack,
  Tooltip,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { ArrowDropDown, Visibility, VisibilityOff } from "@mui/icons-material";
import { SessionManager } from "react-session.manager.sk";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import "../Styles/PhoneNumber.css";

import {
  login,
  createAccount,
} from "../../CustomAxios/Protected/AccountManagment";
import config from "../../Config";

import { Link, useNavigate } from "react-router-dom";
import { PasswordValidator } from "../common/Validator";

export default function CreateAccountForm(props) {
  const { setHeader } = React.useContext(SessionManager);
  const [email, setEmail] = React.useState("");
  const [fname, setFName] = React.useState("");
  const [lname, setLName] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [cpassword, setCPassword] = React.useState("");
  const [error, setError] = React.useState(false);
  const [errorText, setEText] = React.useState("");
  const [errorText2, setEText2] = React.useState("");
  const [dis, setDis] = React.useState(true);
  const [showPassword, setShowPassword] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  let history = useNavigate();

  const handleReset = () => {
    setEmail("");
    setFName("");
    setLName("");
    setPhone(config.phoneNumber.Value);
    setTitle("");
    setPassword("");
    setCPassword("");
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  React.useEffect(() => {
    if (password || cpassword) {
      const Data = PasswordValidator(password, cpassword);
      setError(!Data.valid);
      setEText2(Data.message);
      setEText(Data.message2);
    }
  }, [password, cpassword]);

  React.useEffect(() => {
    if (!error && email && fname && lname && phone && password && title) {
      setDis(false);
    } else setDis(true);
  }, [password, cpassword, email, fname, lname, phone, title, error]);

  const { deviceUID } = React.useContext(SessionManager);

  const emailEndList = ["@myisp.co.ke", "@ke.myisp.co.ke"];
  const [endEmail, setEndEmail] = React.useState(emailEndList[0]);

  const handleCreateAC = (event) => {
    event.preventDefault();
    setLoading(true);
    createAccount({
      email: email + endEmail,
      fname: fname,
      lname: lname,
      password: password,
      telephone: phone,
      title: title,
    }).then((data) => {
      setLoading(false);
      if (data) {
        login(
          {
            email: email + endEmail,
            password: password,
            remember: false,
            deviceUID: deviceUID,
          },
          setHeader,
          history
        );
        props.handleClose();
      }
    });
  };

  return (
    <form>
      <Dialog
        open={true}
        PaperProps={{
          elevation: 0,
        }}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") history("/");
        }}
      >
        <DialogTitle>Create Account</DialogTitle>

        <DialogContent dividers="paper">
          <DialogContentText>
            If you dont have an account, you can create one here.
          </DialogContentText>
          <Stack direction="row" alignItems="stretch">
            <TextField
              margin="normal"
              required
              label="Email"
              type="email"
              value={email}
              onInput={(e) => setEmail(e.target.value)}
              InputLabelProps={{ style: { paddingLeft: "15px" } }}
              fullWidth
              sx={{ flexGrow: 1 }}
            />
            <Tooltip title="Select Email Domain">
              <CardActionArea
                sx={{
                  maxWidth: "fit-content",
                  border: (theme) => `1px solid ${theme.palette.divider}`,
                  borderLeft: "none",
                  borderRadius: 2,
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  px: 1,
                  mt: 2,
                  mb: 1,
                }}
                onClick={() => {
                  const index = emailEndList.indexOf(endEmail);
                  if (index === emailEndList.length - 1)
                    setEndEmail(emailEndList[0]);
                  else setEndEmail(emailEndList[index + 1]);
                }}
              >
                <Stack direction="row" alignItems="center">
                  <ArrowDropDown /> {endEmail}
                </Stack>
              </CardActionArea>
            </Tooltip>
          </Stack>
          <TextField
            margin="normal"
            required
            label="First Name"
            type="text"
            value={fname}
            onInput={(e) => setFName(e.target.value)}
            InputLabelProps={{ style: { paddingLeft: "15px" } }}
            fullWidth
          />
          <TextField
            margin="normal"
            required
            label="Last Name"
            value={lname}
            onInput={(e) => setLName(e.target.value)}
            InputLabelProps={{ style: { paddingLeft: "15px" } }}
            fullWidth
          />
          <TextField
            margin="normal"
            required
            label="Job Title"
            value={title}
            onInput={(e) => setTitle(e.target.value)}
            InputLabelProps={{ style: { paddingLeft: "15px" } }}
            fullWidth
          />
          <PhoneInput
            country={config.phoneNumber.Default}
            value={phone}
            onChange={(e) => setPhone(e)}
            specialLabel="Phone Number"
            required
          />
          <FormControl required fullWidth error={error} margin="normal">
            <InputLabel htmlFor="password">Password</InputLabel>
            <Input
              id="password"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    colour="secondary"
                    size="large"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
            {error && (
              <FormHelperText>
                {errorText2.map((val, index) => {
                  return (
                    <span key={`passhelp_${index}`}>
                      {val}
                      <br />
                    </span>
                  );
                })}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl required fullWidth error={error} margin="normal">
            <InputLabel htmlFor="Confirm-Password">Confirm Password</InputLabel>
            <Input
              id="Confirm-Password"
              type={showPassword ? "text" : "password"}
              value={cpassword}
              onChange={(e) => setCPassword(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    colour="secondary"
                    size="large"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
            {error && <FormHelperText>{errorText}</FormHelperText>}
          </FormControl>
        </DialogContent>
        <DialogActions
          sx={{
            bgcolor: "background.default",
          }}
        >
          <Button onClick={handleReset} color="secondary">
            Reset
          </Button>
          <Button component={Link} to="/" color="warning">
            Cancel
          </Button>
          <LoadingButton
            onClick={handleCreateAC}
            variant="contained"
            type="submit"
            color="primary"
            autoFocus={true}
            disabled={dis}
            loading={loading}
          >
            Create Account
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </form>
  );
}
