import React from "react";
import { useLocation } from "react-router-dom";
import { getQuoteHtml, getQuotePDF } from "../API/SalesAdmin";
import {
  CircularProgress,
  Collapse,
  Fade,
  IconButton,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import { Download, Preview, Refresh } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

export default function QuotieViewer() {
  const urlParameters = new URLSearchParams(useLocation().search);
  const QuoteNumber = urlParameters.get("qno")?.trim();
  const downloadFlag = urlParameters.get("dwn")?.trim();

  urlParameters.delete("dwn");

  const [quoteHtml, setQuoteHtml] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [downloading, setDownloading] = React.useState(
    downloadFlag === "true" || false
  );

  React.useEffect(() => {
    if (QuoteNumber && loading) {
      getQuoteHtml(QuoteNumber)
        .then((response) => {
          if (response.status === 200) {
            const blob = new Blob([response.data], {
              type: "text/html",
            });
            let url = URL.createObjectURL(blob);
            setQuoteHtml(url);
          }
        })
        .finally(() => {
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        });
    }
  }, [QuoteNumber, loading]);

  React.useEffect(() => {
    if (downloading) {
      getQuotePDF(QuoteNumber, "")
        .then((res) => {
          if (res.status === "success") {
            toast.success("Quote Downloaded Successfully", {
              toastId: "quoteDownload",
            });
          }
        })
        .finally(() => {
          setDownloading(false);
        });
    }
  }, [QuoteNumber, downloading]);

  React.useEffect(() => {
    const iframe = document.getElementById("iframe");

    if (iframe) {
      iframe.onload = function () {
        // Get the document inside the iframe
        const iframeDocument =
          iframe.contentDocument || iframe.contentWindow.document;

        // Create a <style> element
        const styleElement = iframeDocument.createElement("style");

        // Set CSS rules
        styleElement.textContent = `
            html {
                overflow-y: scroll;
                overflow-x: hidden;
            }
        `;

        // Append the <style> element to the <head> of the iframe document
        iframeDocument.head.appendChild(styleElement);
      };
    }
  }, [quoteHtml]);

  return (
    <Paper variant="outlined">
      <Paper elevation={1}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Preview />
          <Typography variant="h6">Quote Viewer</Typography>
          <Fade in={!loading}>
            <IconButton onClick={() => setLoading(true)}>
              <Refresh />
            </IconButton>
          </Fade>
        </Stack>

        <Collapse in={downloading}>
          <Stack direction="row" spacing={2} alignItems="center">
            <CircularProgress size={20} thickness={5} color="secondary" />
            <Typography variant="caption">Downloading...</Typography>
          </Stack>
        </Collapse>

        <LoadingButton
          onClick={() => setDownloading(true)}
          startIcon={<Download />}
          loading={downloading}
          disabled={loading || downloading}
        >
          Download PDF
        </LoadingButton>
      </Paper>
      <Collapse in={loading}>
        <Skeleton width={"100%"} height={"29.7cm"} variant="rectangular" />
      </Collapse>
      <Collapse in={!loading && quoteHtml}>
        <iframe
          id="iframe"
          title="A4Page"
          style={{
            width: "100%",
            minHeight: "29.7cm",
          }}
          src={quoteHtml}
        />
      </Collapse>
    </Paper>
  );
}
