import React from "react";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import {
  IconButton,
  Tooltip,
  Typography,
  TextField,
  Collapse,
  Stack,
  Badge,
  TableContainer,
} from "@mui/material";
import { Box } from "@mui/system";
import TableLoader from "../Loading/Table";
import RefreshIcon from "@mui/icons-material/Refresh";
import ClearIcon from "@mui/icons-material/Clear";
import { AddCircle, RemoveCircle } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

export default function Main({
  loading,
  setLoading,
  data,
  headers,
  name,
  reloadBtnLoading,
  subTable,
  hideBadge = false,
}) {
  const head = headers || [];

  const [rows, setRows] = React.useState(data);
  const [searchDets, setSearchDets] = React.useState("");
  const [autoFocus, setAutoFocus] = React.useState(false);

  const filterOptions = React.useMemo(() => {
    return headers
      .filter((column) => column.searchable)
      .map((column) => column.id);
  }, [headers]);

  const filteredRows = React.useMemo(() => {
    if (searchDets) {
      const term = searchDets.toLocaleLowerCase();
      return data.filter((val) => {
        let found = false;
        for (let i = 0; i < filterOptions.length; i++) {
          found =
            found ||
            String(val[filterOptions[i]]).toLocaleLowerCase().includes(term);
        }
        return found;
      });
    }
    return data;
  }, [searchDets, data, filterOptions]);

  React.useEffect(() => {
    setRows(filteredRows);
  }, [filteredRows]);

  const handleSearchChange = React.useCallback((value) => {
    setSearchDets(value);
  }, []);

  return (
    <>
      {loading ? (
        <TableLoader />
      ) : (
        <EnhancedTable
          headers={head}
          name={
            !subTable && (
              <ReloadBtn setLoading={setLoading} loading={reloadBtnLoading} />
            )
          }
          TableTitle={name}
          rows={rows || []}
          resetFlag={searchDets}
          subTable={subTable}
          hideBadge={hideBadge}
        >
          {!subTable && (
            <SearchArea
              current={searchDets}
              setFinalVal={handleSearchChange}
              autoFocus={autoFocus}
              setAutoFocus={setAutoFocus}
              searchName={name}
            />
          )}
        </EnhancedTable>
      )}
    </>
  );
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const headCells = props.headings;

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span
                  style={{
                    border: 0,
                    clip: "rect(0 0 0 0)",
                    height: 1,
                    margin: -1,
                    overflow: "hidden",
                    padding: 0,
                    position: "absolute",
                    top: 20,
                    width: 1,
                  }}
                >
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

function EnhancedTable(props) {
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("");
  const [page, setPage] = React.useState(0);
  const subTable = props.subTable;
  const rows = props.rows;
  const [rowsPerPage, setRowsPerPage] = React.useState(
    subTable ? rows.length : 5
  );

  const dense = true;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const resetFlag = props.resetFlag;

  React.useEffect(() => {
    setPage(0);
  }, [resetFlag, rows]);

  const Wrapper = ({ children }) => {
    if (subTable) return <>{children}</>;
    else return <Paper sx={{ mb: 2 }}>{children}</Paper>;
  };

  return (
    <Wrapper>
      <Typography
        variant={subTable ? "h6" : "h5"}
        color={subTable ? "secondary" : "primary"}
        sx={{ m: 1, pt: 2 }}
      >
        {props.TableTitle}
      </Typography>
      {!subTable && (
        <EnhancedTableToolbar
          SearchArea={props.children}
          TableName={props.name}
        />
      )}
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size={dense ? "small" : "medium"}
        >
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headings={props.headers}
          />
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy), props.headers)
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <DataRow
                    key={index}
                    headers={props.headers}
                    rowValues={row}
                    hideBadge={props.hideBadge}
                  />
                );
              })}
            {!subTable && emptyRows > 0 && (
              <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                <TableCell colSpan={props.headers.length} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        {subTable ? (
          <Box sx={{ mb: 3 }} />
        ) : (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{ overflow: "visible" }}
            showFirstButton
            showLastButton
          />
        )}
      </TableContainer>
    </Wrapper>
  );
}

function DataRow(props) {
  const { rowValues, headers } = props;
  const subTables = headers.filter((header) => header.subRow);
  const component = headers.filter((header) => !header.subRow);
  const hideBadge = props.hideBadge || false;

  const [reveal, setReveal] = React.useState(
    Array(subTables.length).fill(false)
  );

  const revealSubRow = React.useCallback((index) => {
    setReveal((previous) => {
      previous[index] = !previous[index];
      return [...previous];
    });
  }, []);

  const generateCell = React.useCallback(
    (value, key, cellProps) => (
      <TableCell key={key} {...cellProps}>
        {value}
      </TableCell>
    ),
    []
  );

  return (
    <>
      <TableRow tabIndex={-1}>
        {subTables.map((header, index) => (
          <TableCell key={index}>
            <Tooltip
              title={reveal[index] ? `Hide ${header.id}` : `Show ${header.id}`}
            >
              <>
                <IconButton
                  onClick={() => revealSubRow(index)}
                  disabled={rowValues[header.id].length === 0}
                  color={header.iconColor || "secondary"}
                >
                  <Badge
                    badgeContent={rowValues[header.id].length}
                    invisible={hideBadge}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    componentsProps={{
                      badge: {
                        sx: { color: (theme) => theme.palette.text.primary },
                      },
                    }}
                  >
                    {reveal[index]
                      ? header?.closeIcon || <RemoveCircle />
                      : header?.openIcon || <AddCircle />}
                  </Badge>
                </IconButton>
              </>
            </Tooltip>
          </TableCell>
        ))}
        {component.map((header, index) => {
          const value = header.date
            ? new Date(rowValues[header.id]).toDateString() +
              " " +
              new Date(rowValues[header.id]).toLocaleTimeString()
            : rowValues[header.id];
          const cell = header.component
            ? generateCell(header.component(rowValues), index, header.cellProps)
            : generateCell(value, index, header.cellProps);
          return cell;
        })}
      </TableRow>
      {subTables.map((table, index) => (
        <TableRow key={index}>
          <TableCell
            colSpan={props.headers.length + 1}
            style={{ paddingBottom: 0, paddingTop: 0 }}
          >
            <Collapse in={reveal[index]} timeout="auto" unmountOnExit>
              <Main
                loading={table.loading}
                setLoading={table.setLoading}
                data={rowValues[table.id]}
                headers={table.headers}
                name={table.subTitle}
                subTable
              />
            </Collapse>
          </TableCell>
        </TableRow>
      ))}
    </>
  );
}

const EnhancedTableToolbar = (props) => {
  const SearchArea = props.SearchArea;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        pt: 2,
      }}
    >
      <Stack
        spacing={2}
        direction={{ xs: "column", md: "row" }}
        alignItems={{ xs: "flex-start", md: "center" }}
        sx={{ width: "100%" }}
      >
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {props.TableName}
        </Typography>
        {SearchArea}
      </Stack>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator, headers) {
  const dateColumns = headers
    .filter((object) => object.date)
    .map((value) => value.id);
  const stabilizedThis = array.map((el, index) => [{ ...el }, index]);

  stabilizedThis.forEach((item) => {
    const object = item[0];
    for (const property in object) {
      if (dateColumns.includes(property))
        object[property] = Number(new Date(object[property]));
    }
  });

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function SearchArea(props) {
  const { current, setFinalVal } = props;
  const [searchVal, setSearchVal] = React.useState(
    current || sessionStorage.getItem(`searchVal:${props.searchName}`) || ""
  );
  const inputRef = React.useRef(null);

  const setDefault = () => {
    setSearchVal("");
    setFinalVal("");
    sessionStorage.removeItem(`searchVal:${props.searchName}`);
  };

  React.useEffect(() => {
    const timeoutId = setTimeout(() => setFinalVal(searchVal), 700);
    return () => clearTimeout(timeoutId);
  }, [searchVal, setFinalVal]);

  const handleSearch = (e) => {
    setSearchVal(e.target.value);
    sessionStorage.setItem(`searchVal:${props.searchName}`, e.target.value);
  };

  return (
    <div key="SearchArea">
      <TextField
        key="tableSearchField"
        ref={inputRef}
        label="Search"
        variant="outlined"
        value={searchVal}
        color="secondary"
        onChange={handleSearch}
        autoFocus={props.autoFocus}
        onFocus={() => props.setAutoFocus(true)}
        onBlur={() => props.setAutoFocus(false)}
        InputProps={{
          endAdornment: (
            <Tooltip title={"Reset Search"}>
              <IconButton
                aria-label="Reset"
                color="secondary"
                onClick={() => setDefault()}
              >
                <ClearIcon />
              </IconButton>
            </Tooltip>
          ),
        }}
      />
    </div>
  );
}

function ReloadBtn({ setLoading, loading }) {
  return (
    <Tooltip title={"Reload"}>
      <LoadingButton
        variant="contained"
        color="primary"
        onClick={() => setLoading(true)}
        loading={loading}
      >
        <RefreshIcon />
      </LoadingButton>
    </Tooltip>
  );
}
