import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  Grid,
  Box,
  CardActionArea,
  Collapse,
  Paper,
} from "@mui/material";

import EmailIcon from "@mui/icons-material/Email";
import UnsubscribeIcon from "@mui/icons-material/Unsubscribe";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";

import { useNavigate, useLocation } from "react-router-dom";
import { useOutletContext } from "react-router-dom";

import {
  getClientByID,
  updateClient,
  recentlyContacted,
  getAllComaniesNames,
  getCompanyByID,
  updateCompany,
  deleteClient,
} from "../../API/Sales";
import LabelPaper from "../../../Components/common/LabelPaper";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import TieredText from "../common/TieredText";
import ToggleSubscribe from "../common/ToggleSubscribe";
import AsyncAutoComplete from "../../../Components/common/AsyncAutoComplete";
import { Delete } from "@mui/icons-material";

export default function Main() {
  const [userData, setUserData] = React.useState({}),
    [companyData, setCompanyData] = React.useState({}),
    [loading, setLoading] = React.useState(true);

  const [edited, setEdited] = React.useState(false);

  const urlDets = new URLSearchParams(useLocation().search);
  const userID = urlDets.get("i");

  let history = useNavigate();

  const { loadFiltered, origin } = useOutletContext();

  const goBack = (forceEdit = false) => {
    if (forceEdit || edited) loadFiltered();
    history(origin);
  };

  React.useEffect(() => {
    getClientByID(userID)
      .then((res) => {
        setUserData(res);
        getCompanyByID(res.companyID).then((res) => {
          setCompanyData(res);
        });
      })
      .finally(() => setLoading(false));
  }, [userID, loading]);

  const submitChange = (label, value) => {
    const dataValue = label === "company" ? value.id : value;
    const Data = {
      userID: userID,
      name: label,
      value: dataValue,
    };

    return updateClient(Data).then((res) => {
      if (res.status === "success") {
        setEdited(true);
        setLoading(true);
        return true;
      } else {
        toast.error(res.message);
        return false;
      }
    });
  };

  const submitCompanyChange = (label, value) => {
    const data = {
      id: companyData.id,
      name: label,
      value: value,
    };
    return updateCompany(data).then((res) => {
      if (res.status === "success") {
        setEdited(true);
        setLoading(true);
        return true;
      } else {
        toast.error(res.message);
        return false;
      }
    });
  };

  const ChangeCompany = (value, setChange) => {
    return (
      <Box sx={{ mt: 2 }}>
        <AsyncAutoComplete
          label="Company"
          defaultOption={value}
          getData={getAllComaniesNames}
          setValue={setChange}
          optionLabel="name"
        />
      </Box>
    );
  };

  return (
    <Dialog
      open={true}
      onClose={goBack}
      PaperProps={{
        elevation: 0,
        sx: { minWidth: "60vw" },
      }}
    >
      <DialogTitle>Manage User</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ pt: 3 }}>
          <Grid item xs={12} md={6}>
            <LabelPaper label="Client Details" isTable>
              <TieredText
                submitChange={submitChange}
                label="Company"
                name="company"
                value={userData.companyName}
                customEditComponent={ChangeCompany}
                loading={loading}
                alertText="You can change the company here, if you need to edit the companies name please scroll down to the company details section."
              />
              <TieredText
                submitChange={submitChange}
                label="First Name"
                name="fname"
                value={userData.fname}
                loading={loading}
              />
              <TieredText
                submitChange={submitChange}
                label="Last Name"
                name="lname"
                value={userData.lname}
                loading={loading}
              />
              <TieredText
                submitChange={submitChange}
                label="Email"
                name="email"
                value={userData.email}
                loading={loading}
              />
              <TieredText
                submitChange={submitChange}
                label="Phone"
                name="telephone"
                value={userData.phone}
                loading={loading}
              />
              <TieredText
                submitChange={submitChange}
                label="Job Title"
                name="job_title"
                value={userData.jobTitle}
                loading={loading}
              />
            </LabelPaper>
            <Box sx={{ my: 5 }} />
            <LabelPaper label="Company Details" isTable>
              <TieredText
                submitChange={submitCompanyChange}
                label="Company Name"
                name="name"
                value={companyData.name}
                loading={loading}
              />
              <TieredText
                submitChange={submitCompanyChange}
                label="KRA Pin"
                name="KRA"
                value={companyData.KRA}
                loading={loading}
              />
              <TieredText
                submitChange={submitCompanyChange}
                label="Address"
                name="address"
                value={companyData.address}
                loading={loading}
                multiline
              />
              <TieredText
                submitChange={submitCompanyChange}
                label="Note"
                name="note"
                value={companyData.note}
                loading={loading}
                multiline
              />
            </LabelPaper>
            <ToggleSubscribe
              stylingProps={{ mt: 2 }}
              options={[
                {
                  label: "Client will get email updates",
                  value: true,
                  color: "success",
                  icon: <EmailIcon />,
                },
                {
                  label: "Client will not get email updates",
                  value: false,
                  color: "warning",
                  icon: <UnsubscribeIcon />,
                },
              ]}
              value={userData.emailUpdates}
              onChange={(value) => submitChange("email_updates", value)}
              moreInfo="This will toggle whether the client will get email updates from the system. For example order updates."
              loadingData={loading}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <RecentlyContacted loading={loading} clientID={userID} />
            <DeleteClient
              clientId={userID}
              goBack={goBack}
              setEdited={setEdited}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="warning" onClick={goBack}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function RecentlyContacted({ clientID, loadingData }) {
  const [loading, setLoading] = React.useState(false);

  const handleClick = () => {
    setLoading(true);
    recentlyContacted(clientID)
      .then((res) => {
        if (res.status === "success") {
          toast.success(res.message);
        } else {
          toast.error(res.message);
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <LoadingButton
      variant="text"
      color="info"
      sx={{ mt: 2 }}
      startIcon={<ConnectWithoutContactIcon />}
      loading={loadingData || loading}
      onClick={handleClick}
    >
      I have contacted the customer recently
    </LoadingButton>
  );
}

function DeleteClient({ clientId, goBack }) {
  const [open, setOpen] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);

  const onSubmit = () => {
    setDeleting(true);
    deleteClient(clientId)
      .then((res) => {
        if (res.success) {
          toast.success("Client Deleted");
          goBack(true);
        } else {
          toast.warning(
            "Client was not deleted. Either there was an error or the client is in use."
          );
        }
      })
      .finally(() => setDeleting(false));
  };

  return (
    <Box
      sx={{
        bgcolor: "error.main",
        my: 2,
        borderRadius: 1,
        width: "fit-content",
      }}
    >
      <CardActionArea sx={{ p: 1.5 }} onClick={() => setOpen(!open)}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Delete />
          <Typography variant="body1" color="white">
            Delete Client
          </Typography>
        </Stack>
      </CardActionArea>
      <Collapse in={open}>
        <Paper
          elevation={0}
          variant="outlined"
          sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0, p: 2 }}
        >
          <Typography variant="overline">Are you sure?</Typography>
          <Typography variant="body1" color="#9e9e9e">
            This action can not be undone
          </Typography>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-end"
            sx={{ mt: 2 }}
          >
            <LoadingButton
              variant="contained"
              color="error"
              size="small"
              loading={deleting}
              onClick={onSubmit}
            >
              Delete
            </LoadingButton>
            <Button
              size="small"
              variant="outlined"
              color="warning"
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
          </Stack>
        </Paper>
      </Collapse>
    </Box>
  );
}
