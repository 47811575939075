import {
  TableHead,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
} from "@mui/material";
import { Paper, Typography } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";

export default function TableLoader() {
  return (
    <TableContainer
      component={Paper}
      sx={{ my: 1, maxHeight: 427.39, overflow: "hidden" }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography color="inherit" variant="h3" component="div">
                <Skeleton width={70} />
              </Typography>
              <Skeleton height={30} />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              <Skeleton height={30} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Skeleton height={30} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Skeleton height={30} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Skeleton height={30} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Skeleton height={30} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Skeleton width="30%" sx={{ float: "right" }} />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
