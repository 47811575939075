import React from "react";
import { SessionManager } from "react-session.manager.sk";
import { Button, Card, CardContent, Paper, Typography } from "@mui/material";
import { NavigateNext, SelectAll, Settings } from "@mui/icons-material";

export default function EmailSignature() {
  const { userInfo } = React.useContext(SessionManager);

  const tdStyle = {
    color: "#000",
    fontSize: "0.8rem",
  };

  const maincolor = "#e92e27";

  const spanStyle = {
    color: maincolor,
    fontWeight: "bold",
  };

  const handleTableCopy = () => {
    let copyText = document.getElementById("EmailSignatureTable");
    if (document.createRange && window.getSelection) {
      let range = document.createRange();
      let sel = window.getSelection();
      // unselect any element in the page
      sel.removeAllRanges();

      try {
        range.selectNodeContents(copyText);
        sel.addRange(range);
      } catch (e) {
        range.selectNode(copyText);
        sel.addRange(range);
      }
    }
  };

  return (
    <>
      <Typography>Instructions</Typography>
      <Card sx={{ mb: 2 }}>
        <CardContent>
          <ol>
            <li>Press the follwoing button</li>
            <li>
              <Button
                startIcon={<SelectAll />}
                onClick={handleTableCopy}
                variant="contained"
                color="info"
              >
                Select Email Signature
              </Button>
            </li>
            <li>Right click the selection below and copy</li>
            <li>
              Open{" "}
              <a
                href="https://mail.google.com/"
                target="_blank"
                rel="noreferrer"
                style={{
                  textDecoration: "none",
                  color: maincolor,
                }}
              >
                Gmail
              </a>
              .
            </li>
            <li data-outlined="false" class="">
              At the top right, click Settings <Settings fontSize="0.5rem" />{" "}
              <NavigateNext fontSize="0.5rem" />{" "}
              <strong>See all settings</strong>.
            </li>
            <li data-outlined="false" class="">
              In the "Signature" section, paste your signature in the box.
            </li>
            <li data-outlined="false" class="">
              At the bottom of the page, click <strong>Save Changes</strong>.
            </li>
          </ol>
        </CardContent>
      </Card>

      <Paper variant="outlined" sx={{ backgroundColor: "#fff" }}>
        <table
          id="EmailSignatureTable"
          cellPadding={0}
          cellSpacing={0}
          className={"signature"}
          style={{
            background: "rgba(0,0,0,0)",
            fontFamily: "Tahoma",
            color: "#fff",
            marginLeft: "20px",
            marginRight: "20px",
          }}
        >
          <tbody>
            <tr>
              <td
                style={{
                  color: maincolor,
                  fontWeight: "bold",
                }}
              >
                {userInfo.firstName} {userInfo.lastName}
              </td>
            </tr>
            <tr>
              <td
                colSpan={3}
                style={{
                  color: maincolor,
                  fontWeight: "bold",
                  fontSize: "0.9rem",
                }}
              >
                {userInfo.title} |{" "}
                <a
                  href={"https://www.tec-today.com/"}
                  style={{
                    textDecoration: "none",
                    color: maincolor,
                  }}
                >
                  MYISP Limited
                </a>
              </td>
            </tr>
            <tr>
              <td colSpan={3}>
                <hr
                  style={{
                    border: "0",
                    borderTop: "5px solid #e92e27",
                  }}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={3} style={tdStyle}>
                <span style={spanStyle}>Telephone:</span> +254 731 714 012
              </td>
            </tr>
            <tr>
              <td colSpan={3} style={tdStyle}>
                <span style={spanStyle}>Cell:</span> +
                {userInfo.phone.replace(/(\d{3})(?=\d)/g, "$1 ")}
              </td>
            </tr>
            <tr>
              <td colSpan={3} style={tdStyle}>
                <span style={spanStyle}>Email:</span> info@tec-today.com
              </td>
            </tr>
            <tr>
              <td colSpan={3} style={tdStyle}>
                <span style={spanStyle}>Website:</span>{" "}
                <a
                  href={"https://www.tec-today.com/"}
                  style={{
                    textDecoration: "none",
                    ...tdStyle,
                  }}
                >
                  www.tec-today.com
                </a>
              </td>
            </tr>
            <tr>
              <td colSpan={3} style={tdStyle}>
                Maksons Plaza, Parklands Road, Westlands
              </td>
            </tr>
            <tr>
              <td colSpan={3} style={tdStyle}>
                P.O. Box 66163 - 00800 Nairobi, Kenya
              </td>
            </tr>
            <tr>
              <td colSpan={3}>
                <div className={"social-logos-frame"}>
                  <a
                    href={"https://www.tec-today.com/"}
                    style={{
                      textDecoration: "none",
                    }}
                  >
                    <img
                      src="https://www.tec-today.com/wp-content/uploads/2016/02/logo.png"
                      alt={""}
                    />
                  </a>
                </div>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  ...tdStyle,
                  fontSize: "0.6rem",
                  color: "grey",
                }}
              >
                The content of this email is confidential and intended for the
                recipient specified in message only. It is strictly forbidden to
                share any part of this message with any third party, without a
                written consent of the sender. If you received this message by
                mistake, please reply to this message and follow with its
                deletion, so that we can ensure such a mistake does not occur in
                the future.
              </td>
            </tr>
          </tbody>
        </table>
      </Paper>
    </>
  );
}
