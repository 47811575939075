import React from "react";
import {
  Typography,
  Chip,
  Tooltip,
  IconButton,
  Box,
  DialogTitle,
} from "@mui/material";
import UniversalTable from "../../Components/common/UniversalTable";

import AddClient from "../Components/Forms/AddClient";
import { getCompanyPages, getAllComapnies } from "../API/SalesAdmin";

import { Outlet } from "react-router-dom";

import FloatingFormButton from "../../Components/common/FloatingFormButton";

import LazyLoadingIndicator from "../Components/common/LazyLoadingIndicator";

import { Link } from "react-router-dom";

import { MoreVert } from "@mui/icons-material";

import usePagesLoading from "../Components/CustomHooks/PagesLoading";
import DescriptionBubble from "../Components/common/DescriptionBubble";

export default function AdminClientManager() {
  const [reload, setReload] = React.useState(false);

  const startReload = () => {
    setReload(true);
  };

  React.useEffect(() => {
    if (reload) setReload(false);
  }, [reload]);

  const [open, setOpen] = React.useState(false);

  return (
    <>
      <Typography variant="h4" color="primary" gutterBottom>
        Company Manager
      </Typography>
      <FloatingFormButton title="Add new client" open={open} setOpen={setOpen}>
        <DialogTitle>Add new client</DialogTitle>
        <AddClient onComplete={reload} />
      </FloatingFormButton>
      <CompanyManagment reload={reload} />

      <Outlet context={{ startReload, origin: "/companies" }} />
    </>
  );
}

function CompanyManagment({ reload }) {
  const {
    loadingIndicator,
    loading: companyLoading,
    currentPage,
    totalPages,
    data,
    loadFiltered,
  } = usePagesLoading({ getData: getAllComapnies, getPages: getCompanyPages });
  const companyHeaders = [
    {
      id: "companyName",
      label: "Company",
      searchable: true,
    },
    {
      id: "kra",
      label: "KRA",
      searchable: true,
    },
    {
      id: "vetted",
      label: "Vetted",
      component: (value) => (
        <Chip
          label={value.vetted ? "Vetted" : "Not Vetted"}
          color={value.vetted ? "info" : "default"}
          variant={value.vetted ? "default" : "outlined"}
        />
      ),
    },
    {
      id: "note",
      label: "Note",
      component: (value) => (
        <DescriptionBubble
          text={value.note}
          bubbleID={`companynote${value.id}`}
        />
      ),
    },
    {
      id: "settings",
      label: "",
      component: (value) => (
        <Tooltip title="Manage Company">
          <IconButton
            aria-label="settings"
            component={Link}
            to={`/companies/manage?id=${value.id}`}
            sx={{ float: "right" }}
          >
            <MoreVert />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  React.useEffect(() => {
    if (reload) loadFiltered();
  }, [reload, loadFiltered]);

  return (
    <Box sx={{ p: 2 }}>
      <LazyLoadingIndicator
        show={loadingIndicator}
        currentPage={currentPage}
        totalPages={totalPages}
      />
      <UniversalTable
        headers={companyHeaders}
        data={data}
        loading={companyLoading}
        setLoading={loadFiltered}
        name={"Companies"}
      />
    </Box>
  );
}
