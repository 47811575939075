import React from "react";
import {
  Box,
  Grid,
  Paper,
  Stack,
  Typography,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  ToggleButtonGroup,
  ToggleButton,
  InputAdornment,
  Slide,
  Alert,
  AlertTitle,
  Collapse,
} from "@mui/material";
import config from "../../Config";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import NumberFormat from "../../../Components/common/NumberFormater";
import AddClient from "../Forms/AddClient";
import { searchClient } from "../../API/Sales";

import ItemsArea from "./ItemArea";
import { LoadingButton } from "@mui/lab";
import AsyncAutoComplete from "../../../Components/common/AsyncAutoComplete";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { Cancel, ExpandMore, Restore } from "@mui/icons-material";
import DateTimePicker from "./DateTimePicker";

export default function SalesQuoter({
  onSubmit,
  quoteData,
  submitText,
  submitIcon,
  scrollToTop,
  canRecoverQuote,
  handleClose = () => {},
  isProposal,
}) {
  const [validity, setValidity] = React.useState(
      quoteData?.validity || config.ValidityOptions[0]
    ),
    [delivery, setDelivery] = React.useState(
      quoteData?.delivery || config.DeliveryOptions[1]
    ),
    [currency, setCurrency] = React.useState(quoteData?.currency || "KES"),
    [clientRefrence, setClientRefrence] = React.useState(
      quoteData?.clientRefrence || ""
    ),
    [clientID, setClientID] = React.useState(quoteData?.clientID || ""),
    [date, setDate] = React.useState(quoteData?.date || new Date()),
    [vatExempt, setVatExempt] = React.useState(quoteData?.vatExempt || false),
    [itemList, setItemList] = React.useState(quoteData?.itemList || []),
    [itemNotEditing, setItemNotEditing] = React.useState(true),
    [dis, setDis] = React.useState(true),
    [totalPriceExc, setTotalPriceExc] = React.useState(0),
    [totalPriceInc, setTotalPriceInc] = React.useState(0);

  // Used to check if all the details are filled
  React.useEffect(() => {
    if (
      validity &&
      delivery &&
      currency &&
      clientID &&
      date &&
      itemNotEditing &&
      itemList.length > 0
    ) {
      setDis(false);
    } else {
      setDis(true);
    }
  }, [validity, delivery, currency, clientID, date, itemList, itemNotEditing]);

  React.useEffect(() => {
    let totalP = 0;
    itemList.forEach((item) => {
      if (item.margin_type === "percent") {
        totalP += item.cost * (item.margin / 100 + 1) * item.quantity;
      } else {
        totalP +=
          parseFloat(item.cost) + parseFloat(item.margin) * item.quantity;
      }
    });
    const tpi = totalP * config.VAT;

    setTotalPriceExc(NumberFormat(totalP.toFixed(2)));
    setTotalPriceInc(NumberFormat(tpi.toFixed(2)));
  }, [itemList]);

  const handleSubmit = () => {
    onSubmit({
      validity: validity,
      delivery: delivery,
      currency: currency,
      clientRefrence: clientRefrence,
      clientID: clientID?.id,
      date: date,
      vatExempt: vatExempt,
      itemList: itemList,
      proposal: isProposal,
    });
  };

  const ItemIsEditing = (flag) => {
    setItemNotEditing(!flag);
  };

  // Scroll to top if edit
  const myRef = React.useRef(null);

  React.useEffect(() => {
    if (scrollToTop) myRef.current.scrollIntoView();
  }, [scrollToTop]);

  // Check if incomplete quote was saved
  const [innit, setInnit] = React.useState(canRecoverQuote); // If this is the first render
  const [quoteRecovered, setQuoteRecovered] = React.useState(false);
  const [recoverOpen, setRecoverOpen] = React.useState(false);
  const [recoveredQuote, setRecoveredQuote] = React.useState(null);

  React.useEffect(() => {
    setInnit(false); // set to false on first render
  }, []);

  React.useEffect(() => {
    setTimeout(() => {
      if (quoteRecovered) setRecoverOpen(true);
    }, 1000);
  }, [quoteRecovered]);

  // Set local storage
  React.useEffect(() => {
    if (!innit && canRecoverQuote)
      localStorage.setItem(
        "quoteData",
        JSON.stringify({
          validity,
          delivery,
          vatExempt,
          currency,
          clientID,
          clientRefrence,
          date,
          itemList,
        })
      );
  }, [
    innit,
    canRecoverQuote,
    validity,
    delivery,
    vatExempt,
    currency,
    clientID,
    clientRefrence,
    date,
    itemList,
  ]);

  // Check if quote can be recovered
  React.useEffect(() => {
    if (!innit) return;
    const quote = JSON.parse(localStorage.getItem("quoteData"));
    if (quote) {
      quote.date = new Date(quote.date).toDateString();

      if (
        JSON.stringify(quote) !==
        JSON.stringify({
          validity,
          delivery,
          vatExempt,
          currency,
          clientID,
          clientRefrence,
          date: date.toDateString(),
          itemList,
        })
      ) {
        setQuoteRecovered(true);
        setRecoveredQuote(quote);
      }
    }
  }, [
    innit,
    validity,
    delivery,
    vatExempt,
    currency,
    clientID,
    clientRefrence,
    date,
    itemList,
  ]);

  const RecoverQuote = () => {
    const [recLoading, setRecLoading] = React.useState(false);

    const handleRecover = () => {
      setRecLoading(true);
      setValidity(recoveredQuote.validity);
      setDelivery(recoveredQuote.delivery);
      setVatExempt(recoveredQuote.vatExempt);
      setCurrency(recoveredQuote.currency);
      setClientID(recoveredQuote.clientID);
      setClientRefrence(recoveredQuote.clientRefrence);
      setDate(new Date(recoveredQuote.date));

      recoveredQuote.itemList.forEach((item) => {
        setItemList((previous) => [
          ...previous,
          {
            partNumber: item.partNumber,
            recurring: item.recurring,
            description: item.description,
            quantity: parseFloat(item.quantity),
            cost: parseFloat(item.cost),
            margin: parseFloat(item.margin),
            margin_type: item.margin_type,
            editing: item.editing,
          },
        ]);
      });

      setQuoteRecovered(false);
      setRecoverOpen(false);
      setRecLoading(false);
    };
    return (
      <Dialog open={recoverOpen}>
        <DialogTitle>Recover Quote</DialogTitle>
        <DialogContent>
          <DialogContentText>
            An incomplete quote was recovered. Do you want to restore it?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            variant="contained"
            color="success"
            onClick={handleRecover}
            loading={recLoading}
            startIcon={<Restore />}
          >
            Restore
          </LoadingButton>
          <Button
            onClick={() => {
              setQuoteRecovered(false);
              setRecoverOpen(false);
            }}
            variant="contained"
            color="warning"
            startIcon={<Cancel />}
          >
            No
          </Button>
          <Button
            variant="contained"
            color="error"
            startIcon={<CloseIcon />}
            onClick={() => {
              setQuoteRecovered(false);
              setRecoverOpen(false);
              handleClose();
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const CurrencyStartAdornment = () => {
    return <InputAdornment position="start">{currency}</InputAdornment>;
  };

  const bottomAreaStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    position: "fixed",
    bottom: 10,
    right: 0,
    mr: 4,
    width: "fit-content",
    zIndex: 1301,
  };

  const [disabledText, setDisabledText] = React.useState([]);
  const [invalidShow, setInvalidShow] = React.useState(false);

  React.useEffect(() => {
    let text = [];
    if (!validity) text.push("Validity is missing");
    if (!delivery) text.push("Delivery is missing");
    if (!clientID) text.push("No Customer selected");
    if (!date) text.push("Date is missing");
    if (!itemNotEditing)
      text.push("Item is being edited, please add all items to quote");
    if (itemList.length === 0) text.push("No items added");

    setDisabledText(text);
  }, [validity, delivery, currency, clientID, date, itemNotEditing, itemList]);

  return (
    <>
      <Grid container spacing={2} component={Paper}>
        <Grid item xs={12} md={6} p={2} ref={myRef}>
          <Typography variant="h6" color="primary">
            Settings
          </Typography>
          {
            <Stack spacing={2} direction="column">
              <TextFieldToggle
                value={validity}
                setChange={(e) => setValidity(e)}
                label={"Validity"}
                ValueArray={config.ValidityOptions}
                defaultOption={config.ValidityOptions[1]}
              />
              {!isProposal && (
                <TextFieldToggle
                  value={delivery}
                  setChange={(e) => setDelivery(e)}
                  label={"Delivery"}
                  ValueArray={config.DeliveryOptions}
                  defaultOption={config.DeliveryOptions[0]}
                />
              )}

              <Currency
                currency={currency}
                setCurrency={(e) => setCurrency(e)}
              />
              {!isProposal && (
                <ClientRefrence
                  value={clientRefrence}
                  setChange={setClientRefrence}
                />
              )}
            </Stack>
          }
        </Grid>
        <Grid item xs={12} md={6} p={2}>
          <Typography variant="h6" color="primary">
            Details
          </Typography>
          <Stack spacing={2} direction="column">
            <CustomerList value={clientID} setChange={setClientID} />
            <DateTimePicker value={date} setChange={setDate} />
            <ToggleButtonGroup
              value={vatExempt}
              exclusive
              onChange={(e, newVatExempt) => {
                if (newVatExempt !== null) setVatExempt(newVatExempt);
              }}
              aria-label="vat exempt"
              color="info"
            >
              <ToggleButton value={true} aria-label="vat exempt">
                VAT Exempt
              </ToggleButton>
              <ToggleButton value={false} aria-label="vat not exempt">
                VAT Included
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        </Grid>
      </Grid>

      <Box component={Paper} sx={{ mx: 1, mt: 5, p: 1 }}>
        <ItemsArea
          items={itemList}
          setItems={setItemList}
          setEditingItem={ItemIsEditing}
          hasVAT={!vatExempt}
          currency={currency}
          isProposal={isProposal}
        />
      </Box>

      <Paper elevation={1} sx={{ p: 2, mt: 2 }}>
        <Typography variant="h6" color="primary">
          {`${isProposal ? "Proposal" : "Quote"} Totals`}
        </Typography>
        <Stack
          spacing={2}
          direction={{ xs: "column", sm: "row" }}
          sx={{ mt: 2 }}
          justifyContent="flex-end"
        >
          <TextField
            label={`Total ${isProposal ? "Proposal" : "Quote"}  Price Exc`}
            value={totalPriceExc}
            InputProps={{ startAdornment: <CurrencyStartAdornment /> }}
            disabled
          />
          {!vatExempt && (
            <TextField
              label={`Total ${isProposal ? "Proposal" : "Quote"}  Price Inc`}
              value={totalPriceInc}
              InputProps={{
                startAdornment: <CurrencyStartAdornment />,
              }}
              disabled
            />
          )}
        </Stack>
      </Paper>

      <Box height={disabledText.length * 40 + 40} />

      <Slide direction="up" in={dis} mountOnEnter unmountOnExit>
        <Box sx={bottomAreaStyle}>
          <Alert severity="warning">
            <AlertTitle>Missing Details</AlertTitle>
            <Collapse in={invalidShow}>
              <ul>
                {disabledText.map((text, index) => (
                  <li key={index} variant="subtitle1">
                    {text}
                  </li>
                ))}
              </ul>
            </Collapse>
            <Button
              startIcon={
                <ExpandMore
                  sx={{
                    transform: invalidShow ? "rotate(180deg)" : "rotate(0deg)",
                    transition: (theme) =>
                      theme.transitions.create("transform", {
                        duration: theme.transitions.duration.complex,
                      }),
                  }}
                />
              }
              color="warning"
              size="small"
              onClick={() => setInvalidShow(!invalidShow)}
              sx={{
                ml: 5,
              }}
            >
              {invalidShow ? "Hide" : "Show"} Details
            </Button>
          </Alert>
        </Box>
      </Slide>
      <Slide direction="up" in={!dis}>
        <Box sx={bottomAreaStyle}>
          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <Collapse in={vatExempt}>
              <Typography
                variant="overline"
                color="error"
                sx={{
                  bgcolor: "rgba(0, 0, 0, 0.2)",
                  borderColor: "rgba(255, 0, 0, 0.5)",
                  borderRadius: 1,
                  border: 1,
                  p: 1,
                  mt: 1,
                }}
              >
                VAT is not included
              </Typography>
            </Collapse>
            <Button
              variant="contained"
              startIcon={submitIcon}
              onClick={handleSubmit}
              size="large"
              sx={{
                zIndex: 1302,
              }}
            >
              {submitText}
            </Button>
          </Stack>
        </Box>
      </Slide>

      <RecoverQuote />
    </>
  );
}

function TextFieldToggle({
  value,
  setChange,
  label,
  ValueArray,
  defaultOption,
  fluffText,
}) {
  const [isToggle, setIsToggle] = React.useState(
    ValueArray.indexOf(value) !== -1 ? true : false
  );

  const handleButtonClick = () => {
    if (!isToggle) setChange(defaultOption);
    setIsToggle((previous) => !previous);
  };

  const handleAlignment = (event, newAlignment) => {
    if (newAlignment !== null) setChange(newAlignment);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box sx={{ my: 1 }}>
      {isToggle ? (
        <>
          <Typography variant="subtitle1" color="primary">
            {label}
          </Typography>
          <ToggleButtonGroup
            value={value}
            onChange={handleAlignment}
            aria-label={label}
            exclusive
            fullWidth
            color={"info"}
            orientation={isMobile ? "vertical" : "horizontal"}
          >
            {ValueArray.map((option) => (
              <ToggleButton key={option} value={option} aria-label={option}>
                {fluffText} {option}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </>
      ) : (
        <TextField
          label={label}
          value={value}
          onChange={(e) => setChange(e.target.value)}
          color="primary"
          variant="filled"
          error={value === ""}
          autoFocus
          fullWidth
          helperText={!value ? "*Required" : ""}
        />
      )}
      <Button
        color={isToggle ? "info" : "error"}
        sx={{ maxWidth: "fit-content", float: "right" }}
        onClick={handleButtonClick}
        startIcon={isToggle ? <EditIcon /> : <CloseIcon />}
      >
        {isToggle
          ? `Type your own ${label} Option`
          : `Pick from default ${label} Options`}
      </Button>
    </Box>
  );
}

function Currency({ currency, setCurrency }) {
  const handleChange = (event, newAlignment) => {
    if (newAlignment !== null) setCurrency(newAlignment);
  };

  return (
    <ToggleButtonGroup
      value={currency}
      onChange={handleChange}
      aria-label="Currency"
      color="info"
      exclusive
    >
      {config.Currencies.map((cur) => (
        <ToggleButton key={cur.value} value={cur.value}>
          {cur.name}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}

function ClientRefrence({ value, setChange }) {
  const [refrence, setRefrence] = React.useState(value ? true : false);
  return (
    <>
      {refrence ? (
        <>
          <TextField
            label="Client Refrence"
            value={value}
            onChange={(e) => setChange(e.target.value)}
            color="primary"
            variant="filled"
            fullWidth
          />
          <Button
            onClick={() => {
              setChange("");
              setRefrence(false);
            }}
            sx={{ maxWidth: "fit-content" }}
            variant="outlined"
            startIcon={<RemoveIcon />}
          >
            Remove Client refrence
          </Button>
        </>
      ) : (
        <Button
          onClick={() => setRefrence(true)}
          variant="outlined"
          sx={{ maxWidth: "fit-content" }}
          startIcon={<AddIcon />}
        >
          Add Client refrence
        </Button>
      )}
    </>
  );
}

function CustomerList({ value, setChange }) {
  const [addClient, setAddClient] = React.useState(false);

  const closeAddClient = () => {
    setAddClient(false);
  };
  const completeAddClient = (data) => {
    setAddClient(false);
    setChange({ id: data.id, name: data.name });
  };

  return (
    <Stack spacing={2} sx={{ my: 1 }}>
      <AsyncAutoComplete
        getData={searchClient}
        setValue={setChange}
        optionLabel="name"
        label="Customers"
        defaultOption={value?.name}
        TextFieldProps={{
          required: true,
        }}
      />

      <Button
        variant="outlined"
        color="primary"
        startIcon={<AddIcon />}
        sx={{ maxWidth: "fit-content", marginRight: 2 }}
        onClick={() => setAddClient(true)}
      >
        Add new client
      </Button>

      <Dialog open={addClient} onClose={closeAddClient}>
        <DialogTitle>Add new client</DialogTitle>
        <AddClient onClose={closeAddClient} onComplete={completeAddClient} />
      </Dialog>
    </Stack>
  );
}
