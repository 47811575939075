import React from "react";
import PropTypes from "prop-types";
import {
  Tooltip,
  IconButton,
  Box,
  Grid,
  Button,
  Stack,
  InputAdornment,
  Chip,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  TextField,
  Grow,
  AlertTitle,
  Alert,
  Collapse,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { Cancel, Search } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import TuneIcon from "@mui/icons-material/Tune";

import AsyncAutoComplete from "./AsyncAutoComplete";
import DateTimePicker from "../../MYISP/Components/common/DateTimePicker";

/*
Inputs:
- categories (type array of objects)
    - category label, type, options, value, setValue
- startFilter (type function)
types of options:
- select
- radio with max of n objects
- checkbox, like select, but with multiple values and will use tags
- range
- date range
*/

FilterArea.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      options: PropTypes.array,
      value: PropTypes.node,
      setValue: PropTypes.func.isRequired,
    })
  ).isRequired,
  startFilter: PropTypes.func.isRequired,
  clearFilter: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

function FilterArea({ categories, startFilter, clearFilter, isLoading }) {
  const [open, setOpen] = React.useState(false);

  const filterBtnClicked = () => {
    setOpen(!open);
  };

  return (
    <>
      <Button onClick={filterBtnClicked} endIcon={<TuneIcon />}>
        Filter
      </Button>

      <Collapse in={open}>
        <Box>
          <Grid container spacing={3} sx={{ my: 3 }}>
            {categories.map((category) => (
              <GridItem
                key={category.label}
                label={category.label}
                type={category.type}
                options={category.options}
                value={category.value}
                setValue={category.setValue}
                getData={category.getData}
                optionLabel={category.optionLabel}
                valueLabel={category.valueLabel}
                clear={category.clear}
                setClear={category.setClear}
                advancedOpen={open}
              />
            ))}
          </Grid>
          <Stack spacing={2} direction="row">
            <LoadingButton
              variant="contained"
              color="primary"
              startIcon={<Search />}
              onClick={() => {
                startFilter();
                setOpen(false);
              }}
              loading={isLoading}
            >
              Search
            </LoadingButton>
            <Button
              variant="outlined"
              color="warning"
              startIcon={<ClearIcon />}
              onClick={() => {
                clearFilter();
                startFilter();
                setOpen(false);
              }}
            >
              Clear all filters
            </Button>
          </Stack>
        </Box>
      </Collapse>

      <FilterChips categories={categories} startFilter={startFilter} />
    </>
  );
}

function FilterChips({ categories, startFilter }) {
  const chipDisplay = categories.filter((category) => {
    if (category.type === "date" && category.value[0] === "") return false;
    return category.value !== "";
  });

  const [filter, setFilter] = React.useState(false);

  React.useEffect(() => {
    if (filter) {
      startFilter();
      setFilter(false);
    }
  }, [filter, startFilter]);

  return (
    <Collapse in={chipDisplay.length > 0}>
      <Stack direction={{ xs: "column", md: "row" }} spacing={1} sx={{ my: 2 }}>
        {chipDisplay.map((category) => {
          let extraText = "";

          if (category.type === "date") {
            extraText =
              category.label + ": " + category.value[0].toUpperCase() + " - ";

            if (category.value[0] === "less")
              extraText += new Date(category.value[2]).toLocaleDateString();
            else extraText += new Date(category.value[1]).toLocaleDateString();

            if (category.value[0] === "between") {
              extraText +=
                " To " + new Date(category.value[2]).toLocaleDateString();
            }
          }

          const chipLabel =
            category.type !== "date"
              ? category.label + ": " + category.value
              : extraText;

          const onClearFilterChip = () => {
            if (category.type === "date") category.setValue(["", null, null]);
            else if (category.type === "radio")
              category.setValue(category.options[0]);
            else category.setValue("");
            setFilter(true);
          };

          return (
            <Chip
              key={category.label}
              label={chipLabel}
              onDelete={onClearFilterChip}
              variant="outlined"
              color="info"
              sx={{ width: "fit-content" }}
            />
          );
        })}
      </Stack>
    </Collapse>
  );
}

function GridItem({
  type,
  label,
  options,
  value,
  setValue,
  getData,
  optionLabel,
  valueLabel,
  clear,
  setClear,
  advancedOpen,
}) {
  const gridsizing = {
    xs: 12,
    md: 6,
    lg: 6,
  };

  const filterOptions = ["select", "radio", "checkbox", "range", "date"];

  switch (type) {
    case "select":
      return (
        <Grid item xs={gridsizing.xs} md={gridsizing.md} lg={gridsizing.lg}>
          <CategorySelect
            label={label}
            options={options}
            value={value}
            setValue={setValue}
          />
        </Grid>
      );
    case "radio":
      return (
        <Grid item xs={gridsizing.xs} md={gridsizing.md} lg={gridsizing.lg}>
          <RadioGroup
            label={label}
            options={options}
            value={value}
            setValue={setValue}
          />
        </Grid>
      );
    case "checkbox":
      return (
        <Grid item xs={gridsizing.xs} md={gridsizing.md} lg={gridsizing.lg}>
          <TagSearch
            label={label}
            options={options}
            value={value}
            setValue={setValue}
          />
        </Grid>
      );
    case "range":
      return (
        <Grid item xs={gridsizing.xs} md={gridsizing.md} lg={gridsizing.lg}>
          <PickRange
            label={label}
            options={options}
            value={value}
            setValue={setValue}
          />
        </Grid>
      );
    case "date":
      return (
        <Grid item xs={gridsizing.xs} md={gridsizing.md} lg={gridsizing.lg}>
          <DateRange
            label={label}
            options={options}
            value={value}
            setValue={setValue}
          />
        </Grid>
      );
    case "search":
      return (
        <Grid item xs={gridsizing.xs} md={gridsizing.md} lg={gridsizing.lg}>
          <InputSearch
            label={label}
            options={options}
            value={value}
            setValue={setValue}
          />
        </Grid>
      );
    case "combo":
      return (
        <Grid item xs={gridsizing.xs} md={gridsizing.md} lg={gridsizing.lg}>
          <ComboSearchBox
            label={label}
            options={options}
            value={value}
            setValue={setValue}
            getData={getData}
            optionLabel={optionLabel}
            valueLabel={valueLabel}
            clear={clear}
            setClear={setClear}
            advancedOpen={advancedOpen}
          />
        </Grid>
      );

    default:
      return (
        <Grid item xs={gridsizing.xs} md={gridsizing.md} lg={gridsizing.lg}>
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            There is no filter option called <strong>{type}</strong>, your
            options are as follows:
            <ul>
              {filterOptions.map((option) => (
                <li key={option}>{option}</li>
              ))}
            </ul>
          </Alert>
        </Grid>
      );
  }
}

function ComboSearchBox({
  setValue,
  label,
  getData,
  optionLabel,
  valueLabel,
  clear,
  setClear,
  advancedOpen,
  value,
}) {
  return (
    <Stack spacing={2}>
      <AsyncAutoComplete
        getData={getData}
        setValue={setValue}
        label={label}
        optionLabel={optionLabel}
        valueLabel={valueLabel}
        clear={clear}
        setClear={setClear}
        allowSearch={advancedOpen}
        value={value}
      />
    </Stack>
  );
}

function InputSearch({ value, setValue, label }) {
  const [holder, setHolder] = React.useState(value);

  // resets holders when clear filter is clicked
  React.useEffect(() => {
    if (value === "") {
      setHolder("");
    }
  }, [value]);
  // delats setting of the value to prevent rerender when typing
  React.useEffect(() => {
    const handleChange = setTimeout(setValue, 200, holder);

    return () => clearTimeout(handleChange);
  }, [holder, setValue]);

  return (
    <Stack spacing={2}>
      <TextField
        id="outlined-basic"
        label={label}
        variant="outlined"
        value={holder}
        onChange={(e) => setHolder(e.target.value)}
      />
    </Stack>
  );
}

function CategorySelect({ value, setValue, label, options }) {
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <FormControl fullWidth>
      <InputLabel id={`${label}-select-label`}>{label}</InputLabel>
      <Select
        labelId={`${label}-select-label`}
        value={value}
        label={label}
        onChange={handleChange}
      >
        <MenuItem value="">None</MenuItem>
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

function RadioGroup({ value, setValue, label, options }) {
  React.useEffect(() => {
    if (value === "") {
      setValue(options[0]);
    }
  }, [value, setValue, options]);

  const handleChange = (event, newAlignment) => {
    if (newAlignment !== null) {
      setValue(newAlignment);
    }
  };

  return (
    <Stack spacing={2}>
      <Typography>{label}</Typography>
      <ToggleButtonGroup value={value} exclusive onChange={handleChange}>
        {options.map((option) => (
          <ToggleButton key={option} value={option}>
            {option}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Stack>
  );
}

function TagSearch({ value, setValue, label, options }) {
  // Takes an array as input and returns an array using setValue
  const handleChange = (event) => {
    const newTag = event.target.value;
    if (newTag && !value.includes(newTag)) {
      setValue([...value, newTag]);
    }
  };

  const removeTag = (tag) => {
    setValue(value.filter((t) => t !== tag));
  };

  const ClearAll = () => {
    setValue([]);
  };

  return (
    <FormControl fullWidth>
      <InputLabel id={`${label}-select-label`}>{label}</InputLabel>
      <Select
        labelId={`${label}-select-label`}
        value={""}
        label={label}
        onChange={handleChange}
        startAdornment={
          <InputAdornment position="start">
            {value.map((tag) => (
              <Chip
                key={tag}
                label={tag}
                onDelete={() => removeTag(tag)}
                color="primary"
                sx={{ mr: 1 }}
              />
            ))}
          </InputAdornment>
        }
        endAdornment={
          <InputAdornment position="end" sx={{ mr: 2 }}>
            <Tooltip title="Clear All">
              <IconButton onClick={ClearAll}>
                <Cancel />
              </IconButton>
            </Tooltip>
          </InputAdornment>
        }
      >
        <MenuItem value="">None</MenuItem>
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

function PickRange({ value, setValue, label, options }) {
  React.useEffect(() => {
    if (value.length === 0) setValue(["between", 0, 0]);
  }, [value, setValue]);

  const type = value[0] || "between",
    firstVal = value[1] || 0,
    secondVal = value[2] || 0;

  const [labelText1, setLabelText1] = React.useState(""),
    [labelText2, setLabelText2] = React.useState("");

  React.useEffect(() => {
    if (type === "between") {
      setLabelText1("From");
      setLabelText2("To");
      setValue(["between", 0, 0]);
    } else {
      setLabelText1("Value");
      setLabelText2("");
      setValue([type, 0, 0]);
    }
  }, [type, setValue]);

  const handleChange = (event, valueIndex) => {
    const newValue = [...value];
    newValue[valueIndex] = event.target.value;
    setValue(newValue);
  };

  return (
    <Stack spacing={2}>
      <Typography>{label}</Typography>
      <Stack direction="row" spacing={2}>
        <FormControl>
          <InputLabel id={`${label}-type-picker`}>Type</InputLabel>
          <Select
            labelId={`${label}-type-picker`}
            value={type}
            label="Type"
            onChange={(event) => {
              handleChange(event, 0);
            }}
            sx={{ width: "fit-content", mr: 2 }}
          >
            <MenuItem value="between">Between</MenuItem>
            <MenuItem value="less">Less Than</MenuItem>
            <MenuItem value="greater">Greater Than</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label={labelText1}
          value={firstVal}
          type="number"
          onWheel={(e) => e.target.blur()}
          onChange={(event) => handleChange(event, 1)}
        />
        <Grow in={type === "between"}>
          <TextField
            label={labelText2}
            value={secondVal}
            type="number"
            onWheel={(e) => e.target.blur()}
            onChange={(event) => handleChange(event, 2)}
          />
        </Grow>
      </Stack>
    </Stack>
  );
}

function DateRange({ value, setValue, label, options }) {
  React.useEffect(() => {
    if (value.length === 0) setValue(["", null, null]);
  }, [value, setValue]);

  const type = value[0] || "",
    firstVal = value[1],
    secondVal = value[2];

  const [labelText1, setLabelText1] = React.useState(""),
    [labelText2, setLabelText2] = React.useState("");

  React.useEffect(() => {
    if (type === "between") {
      setLabelText1("From");
      setLabelText2("To");
      setValue(["between", 0, Date.now()]);
      return;
    }
    if (type === "less") {
      setLabelText1("");
      setLabelText2("Value");
      setValue([type, 0, Date.now()]);
      return;
    }
    if (type === "greater") {
      setLabelText1("Value");
      setLabelText2("");
      setValue([type, Date.now(), 0]);
      return;
    }
    if (type === "") {
      setLabelText1("");
      setLabelText2("");
      setValue([type, null, null]);
      return;
    }
  }, [type, setValue]);

  const handleChange = (newDateVal, valueIndex) => {
    const newValue = [...value];
    newValue[valueIndex] = newDateVal;
    setValue(newValue);
  };

  return (
    <Stack spacing={2}>
      <Stack direction={type === "" ? "column" : "row"} spacing={2}>
        <FormControl>
          <InputLabel id={`${label}-type-picker`}>{label}</InputLabel>
          <Select
            labelId={`${label}-type-picker`}
            value={type}
            label={label}
            onChange={(event) => {
              handleChange(event.target.value, 0);
            }}
            sx={{ minWidth: "fit-content", mr: 2 }}
          >
            <MenuItem value="">None</MenuItem>
            <MenuItem value="between">Between</MenuItem>
            <MenuItem value="less">Less Than</MenuItem>
            <MenuItem value="greater">Greater Than</MenuItem>
          </Select>
        </FormControl>
        {(type === "between" || type === "greater") && (
          <DateTimePicker
            label={labelText1}
            value={firstVal}
            setChange={(event) => handleChange(event.getTime(), 1)}
          />
        )}
        {(type === "between" || type === "less") && (
          <DateTimePicker
            label={labelText2}
            value={secondVal}
            setChange={(event) => handleChange(event.getTime(), 2)}
          />
        )}
      </Stack>
    </Stack>
  );
}

export default FilterArea;
